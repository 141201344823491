import * as React from 'react';
import qs from 'qs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Fragment } from 'react';
import { RichTextInput } from 'ra-input-rich-text';
import { makeStyles } from 'tss-react/mui';
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, Form, useResourceContext, useNotify, FunctionField, ImageInput, SelectArrayInput, ReferenceArrayField, ArrayInput, SimpleFormIterator, TabbedForm, FormDataConsumer, useRecordContext, useRefresh } from 'react-admin';
import {
  useListContext,
  useRedirect,
  List,
  Datagrid,
  Filter,
  Edit,
  Create,

  Toolbar,
  TopToolbar,
  DeleteWithConfirmButton,
  BulkDeleteWithConfirmButton,
  Show,
  SimpleShowLayout,

  ReferenceField,
  TextField,
  ImageField,
  BooleanField,

  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  NumberInput,
  BooleanInput,
  NullableBooleanInput,

  Button,
  EditButton,
  ShowButton,
  SaveButton,
} from 'react-admin';
import { useForm, useFormContext } from 'react-hook-form';
import { Button as MButton, Box, useMediaQuery, Stack, dividerClasses, Chip, Typography, CircularProgress } from '@mui/material';
import { PreviewImage, UpLoadFile } from './custom/UpLoadFile';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import DoneIcon from '@mui/icons-material/Done';
import { PTypes, PublishQuDaos, apiUrl } from '../../global';
import { httpClient } from '../dataProvider';
import { useMutation, useQuery } from 'react-query';
import moment from 'moment';
//import ResetOrderNum from './Pro_Product_ResetOrderNum';

{/* 
  import { Pro_ProductList, Pro_ProductCreate, Pro_ProductEdit, Pro_ProductShow } from './components/Pro_Product';

  <Resource name="CMSPro_Product" list={Pro_ProductList} create={Pro_ProductCreate} edit={Pro_ProductEdit} show={Pro_ProductShow} />
  <Resource name="CMSPro_Product" list={ListGuesser} create={EditGuesser} edit={EditGuesser} edit={ShowGuesser} /> 
*/}

const useStyles = makeStyles()((theme) => {
  return {
    imageField: {
      '& img.RaImageField-image': { width: 60 }
    },
  };
});


const useTxtData = {
  page: {
    tableName: 'Pro_Product',
  },
  table: {
    id: 'id',
    Pro_SortId: 'Pro_SortId',
    Pro_BrandId: 'Pro_BrandId',
    Code: '货号',
    Name: '名称',
    Title: '销售标题',
    Pics: '官图',
    Photos: '手拍图',
    DescPics: '描述图',
    MinAge: '最小月',
    MaxAge: '最大月',
    LingShouJia: '零售价',
    Tags: '标签',
    Desc: '官方介绍',
    Info1: 'Info1',
    Info2: 'Info2',
    Info3: 'Info3',
    Info4: 'Info4',
    UpdateTime: 'UpdateTime',
    AtTime: 'AtTime',
  }
};

//分页列表页面
export const Pro_ProductList = () => {
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  // 筛选器模块
  // const Filters = () => {
  //   return (
  //     <Filter>
  //       <TextInput label="ID" source="id" alwaysOn />
  //     </Filter>
  //   );
  // };
  const MobileFilters = [
    <TextInput label="关键词" source="Key" alwaysOn resettable />
  ];
  const Filters = (props: any) => {
    const { displayedFilters, filterValues, setFilters, hideFilter, refetch } = useListContext();
    // if (isSmall) return null;
    if (props.context === "button") return null;
    const onSubmit = (values: any) => { if (Object.keys(values).length > 0) { setFilters(values, displayedFilters); } else { setFilters({}, displayedFilters); } refetch(); };
    const resetFilter = () => { setFilters({}, displayedFilters); };
    return (
      <div>
        <Form onSubmit={onSubmit} defaultValues={filterValues}>
          <Stack direction={'row'} alignItems={'baseline'} spacing={1.5}>
            <TextInput label="关键词" source="Key" alwaysOn resettable />
            <MButton variant="contained" color="primary" type="submit" startIcon={<SearchIcon />}>搜索</MButton>
            <MButton variant="outlined" onClick={resetFilter} startIcon={<ClearAllIcon />}>重置</MButton>
          </Stack>
        </Form>
      </div>
    );
  };

  //批量操作模块
  const PostBulkActionButtons = () => {
    return (
      <Fragment>
        {/* <ResetOrderNum label="重置排序"  /> */}
        {/* <BulkDeleteWithConfirmButton confirmTitle="删除确认" confirmContent="确认要删除所选记录吗？" mutationMode='undoable' /> */}
      </Fragment>
    );
  };
  //行右侧功能按钮
  const RowAction = () => {
    return (
      <div style={{ textAlign: 'right' }}>
        <EditButton /><br />
        <ShowButton /><br />
        {/* <DeleteWithConfirmButton confirmTitle="删除确认" confirmContent="确认要删除该记录吗？" /> */}
      </div>
    );
  }

  const { classes } = useStyles();
  return (
    <List
      title={useTxtData.page.tableName + '列表'}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<Filters />}
      exporter={false}
      sx={{ "& .MuiToolbar-root .MuiToolbar-root": { height: 0, minHeight: 0 } }}
    >
      <Datagrid
        // bulkActionButtons={<PostBulkActionButtons />}
        bulkActionButtons={false}
      >
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        <TextField label={useTxtData.table.id} source="id" />
        {/* <TextField label={useTxtData.table.Pro_SortId} source="Pro_SortId" />
        <TextField label={useTxtData.table.Pro_BrandId} source="Pro_BrandId" /> */}

        <FunctionField label={'产品'} render={({ MinAge, MaxAge, LingShouJia }: any) => {
          return <>
            <div><TextField source="Title" /></div>
            <div><TextField source="Name" /></div>
            <div><TextField source="Code" sx={{ mr: 1 }} />{LingShouJia && <span>¥<TextField source="LingShouJia" /></span>}
            </div>
            <div>
              <TextField source="MinAge" />
              {MaxAge && <><span>-</span><TextField source="MaxAge" /></>}
              <span>个月</span>
            </div>
          </>
        }} />

        <FunctionField label="发布" render={({ SaleQDs }: any) => {
          if (SaleQDs.length == 1 && SaleQDs[0] === '') return null;
          return SaleQDs.map((saleqd: string) => <div><Chip icon={<DoneIcon />} size='small' label={saleqd} /></div>)
        }} />

        <FunctionField label={useTxtData.table.Pics} sx={{ display: 'block', maxWidth: 320 }} render={({ Pics }: any) => {
          const picArr = Pics;
          if (picArr.length == 1 && picArr[0] === '') return null;
          return picArr.map(({ src }: any) => <a target='_blank' href={src}><img style={{ width: 100, height: 100 }} src={`https://img.scxiniu.com/unsafe/${80}x${80}/${src}`} /></a>)
        }} />

        {/* <TextField label={useTxtData.table.Photos} source="Photos" /> */}
        {/* <TextField label={useTxtData.table.DescPics} source="DescPics" /> */}

        {/* <TextField label={useTxtData.table.LingShouJia} source="LingShouJia" /> */}
        {/* <TextField label={useTxtData.table.Tags} source="Tags" /> */}
        {/* <TextField label={useTxtData.table.Desc} source="Desc" /> */}
        {/* <TextField label={useTxtData.table.Info1} source="Info1" />
        <TextField label={useTxtData.table.Info2} source="Info2" />
        <TextField label={useTxtData.table.Info3} source="Info3" />
        <TextField label={useTxtData.table.Info4} source="Info4" /> */}
        {/* <FunctionField label={'日期'} render={({ }: any) => {
          return <>
            <div><TextField source="UpdateTime" /></div>
            <div><TextField source="AtTime" /></div>
          </>
        }} /> */}
        <RowAction />
      </Datagrid>
    </List>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//SHOW页面
export const Pro_ProductShow = () => {
  const ShowActions = () => {
    const resource = useResourceContext();
    const redirect = useRedirect();
    return (
      <TopToolbar>
        <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text">
          <ArrowBackIcon />
        </Button>
        <EditButton />
      </TopToolbar>
    );
  };

  const { classes } = useStyles();
  return (
    <Show title={'查看' + useTxtData.page.tableName} actions={<ShowActions />}>
      <SimpleShowLayout>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        <TextField label={useTxtData.table.id} source="id" />
        <TextField label={useTxtData.table.Pro_SortId} source="Pro_SortId" />
        <TextField label={useTxtData.table.Pro_BrandId} source="Pro_BrandId" />
        <TextField label={useTxtData.table.Code} source="Code" />
        <TextField label={useTxtData.table.Name} source="Name" />
        <TextField label={useTxtData.table.Title} source="Title" />
        <TextField label={useTxtData.table.Pics} source="Pics" />
        <TextField label={useTxtData.table.Photos} source="Photos" />
        <TextField label={useTxtData.table.DescPics} source="DescPics" />
        <TextField label={useTxtData.table.MinAge} source="MinAge" />
        <TextField label={useTxtData.table.MaxAge} source="MaxAge" />
        <TextField label={useTxtData.table.LingShouJia} source="LingShouJia" />
        <TextField label={useTxtData.table.Tags} source="Tags" />
        <TextField label={useTxtData.table.Desc} source="Desc" />
        <TextField label={useTxtData.table.Info1} source="Info1" />
        <TextField label={useTxtData.table.Info2} source="Info2" />
        <TextField label={useTxtData.table.Info3} source="Info3" />
        <TextField label={useTxtData.table.Info4} source="Info4" />
        <TextField label={useTxtData.table.UpdateTime} source="UpdateTime" />
        <TextField label={useTxtData.table.AtTime} source="AtTime" />
      </SimpleShowLayout>
    </Show>
  );
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//编辑页面
export const Pro_ProductEdit = () => {
  const resource = useResourceContext();
  const redirect = useRedirect();

  const PostEditActions = () => {
    return (
      <TopToolbar >
        <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text">
          <ArrowBackIcon />
        </Button>
      </TopToolbar>
    );
  };
  const PostEditToolbar = () => {
    return (
      <Toolbar >
        <SaveButton />
      </Toolbar>
    );
  };
  return (
    <Edit redirect={`/${resource}`} title={'编辑' + useTxtData.page.tableName} actions={<PostEditActions />}>
      <MyForm Edit={true} toolbar={<PostEditToolbar />} />
    </Edit>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//创建页面
export const Pro_ProductCreate = () => {
  const resource = useResourceContext();
  const redirect = useRedirect();
  const notify = useNotify();

  const PostCreateActions = () => {
    return (
      <TopToolbar>
        <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text" >
          <ArrowBackIcon />
        </Button>
      </TopToolbar>
    );
  };
  const PostCreateToolbar = () => {
    const { reset, resetField, handleSubmit, setValue, getValues, } = useFormContext();
    return (
      <Toolbar>
        <SaveButton alwaysEnable label="保存&列表" />
        <SaveButton alwaysEnable type='button' label="保存&继续" variant="text" mutationOptions={{
          onSuccess: () => {
            notify('创建成功', { type: 'success' });
            reset();
          }
        }} />
      </Toolbar>
    );
  };

  return (
    <Create redirect={`/${resource}`} title={'新增' + useTxtData.page.tableName} actions={<PostCreateActions />}>
      <MyForm Create={true} toolbar={<PostCreateToolbar />} />
    </Create>
  );
};

const _PublishQuDaos = () => {
  return PublishQuDaos.map(t => { return { id: t, name: t } })
}

const FindPrice = () => {
  const resource = useResourceContext();
  const { Code, LingShouJia } = useRecordContext();
  const { data, isLoading, isSuccess, isError, error } = useQuery('FindPrice', () => {
    return httpClient(`${apiUrl}/${resource}/FindPrice`, {
      method: 'POST',
      body: qs.stringify({ Code }),
      contentType: 'application/x-www-form-urlencoded'
    });
  }, { refetchOnWindowFocus: false });

  if (isLoading) return <CircularProgress size={12} />;
  if (isError) return <Typography variant='caption'>{`${error}`}</Typography>;
  if (isSuccess) {
    const { Code, Message, Result } = JSON.parse(data.body) as any;
    if (Code === 1) {
      console.log('FindPrice', Result);
      const { prices, fpLogs, dfPrices } = Result;
      // const zekou = parseFloat((Price_GHJ / LingShouJia).toFixed(2)) * 10;
      // const zekou_2 = (Price_GHJ / 1.13).toFixed(2);
      // return <Typography variant='caption'>(<span style={{ color: 'red' }}>查</span>)供:{`¥${Price_GHJ}(${zekou}折) ¥${zekou_2}(未税)`}</Typography>
      return <Stack sx={{ ml: 3 }}>
        <Typography variant='h6' sx={{ fontSize: 16 }}>开票记录</Typography>
        <Table size="small" sx={{ mb: 2 }}>
          <TableHead>
            <TableRow>
              <TableCell>货品</TableCell>
              <TableCell align="right">公司</TableCell>
              <TableCell align="right">价格</TableCell>
              <TableCell align="right">税/期</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fpLogs.map(({ id, HuoPinCode, HuoPinTitle, HuoPinDanJia, ShuiLv, xiaoshou_Name, goumaifang_Name, fapiaoTime }: any, _ind: number) => {
              var HuoPinDanJia_HanShui = HuoPinDanJia * (1 + ShuiLv);
              var _HuoPinTitle = HuoPinTitle.replace('*玩具*', '');
              return <TableRow
                key={`fp_${id}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  [{HuoPinCode}]<br />
                  {_HuoPinTitle.length > 4 ? _HuoPinTitle.substr(0, 4) : _HuoPinTitle}
                </TableCell>
                <TableCell align="right">
                  {goumaifang_Name.length > 4 ? xiaoshou_Name.substr(0, 4) : goumaifang_Name}<br />
                  {goumaifang_Name.length > 4 ? goumaifang_Name.substr(0, 4) : goumaifang_Name}
                </TableCell>
                <TableCell align="right">
                  {HuoPinDanJia.toFixed(2)}<br />
                  <span style={{ color: 'red' }}>{HuoPinDanJia_HanShui.toFixed(2)}</span>
                </TableCell>
                <TableCell align="right">
                  {ShuiLv}
                  <br />
                  {moment(fapiaoTime).format("M/D")}
                </TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
        <Typography variant='h6' sx={{ fontSize: 16 }}>Hape系统价格</Typography>
        <Table size="small" sx={{ mb: 2 }}>
          <TableHead>
            <TableRow>
              <TableCell>货品</TableCell>
              <TableCell align="right">名称</TableCell>
              <TableCell align="right">价格</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {prices.map(({ id, Code, Name, Price_GHJ, Price_Date }: any, _ind: number) => {
              return <TableRow
                key={`fp_${id}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {Code}
                </TableCell>
                <TableCell align="right">
                  {Name}
                </TableCell>
                <TableCell align="right">
                  <span style={{ color: 'red' }}>{Price_GHJ}</span>
                </TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
        <Typography variant='h6' sx={{ fontSize: 16 }}>代发报价</Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>店铺</TableCell>
              <TableCell align="right">货品</TableCell>
              <TableCell align="right">名称</TableCell>
              <TableCell align="right">价格</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dfPrices.map(({ id, Code, ShopName, Title = "", GHPrice }: any, _ind: number) => {
              Title = Title.replace("Hape", "").replace("hape", "");
              return <TableRow
                key={`fp_${id}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {ShopName}
                </TableCell>
                <TableCell align="right">
                  {Code}
                </TableCell>
                <TableCell align="right">
                  {Title.length > 4 ? Title.substr(0, 4) : Title}
                </TableCell>
                <TableCell align="right">
                  <span style={{ color: 'red' }}>{GHPrice}</span>
                </TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </Stack>

    }
    else
      return <Typography variant='caption'>{Message}</Typography>
  }

  return null;
}
const CeSuanPrice = () => {
  const { LingShouJia } = useRecordContext();
  const p_45 = (LingShouJia * 0.45).toFixed(2);
  const p_45_2 = (parseFloat(p_45) / 1.13).toFixed(2);
  const p_40 = (LingShouJia * 0.4).toFixed(2);
  const p_40_2 = (parseFloat(p_40) / 1.13).toFixed(2);
  const p_35 = (LingShouJia * 0.35).toFixed(2);
  const p_35_2 = (parseFloat(p_35) / 1.13).toFixed(2);
  return <Stack direction={'row'} spacing={1}>
    <Typography variant='caption'>(算)供:{`¥${p_45}(4.5折) ¥${p_45_2}(未税)`}</Typography>
    <Typography variant='caption'>(算)供:{`¥${p_40}(4.0折) ¥${p_40_2}(未税)`}</Typography>
    <Typography variant='caption'>(算)供:{`¥${p_35}(3.5折) ¥${p_35_2}(未税)`}</Typography>
  </Stack>
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//Create和Edit公用表单
const MyForm = (props: any) => {
  let { Edit, Create } = props;
  const resource = useResourceContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const { mutate: csFindPrice_FaPiao, isLoading: csFindPrice_isLoading } = useMutation(
    () => {
      // const { id } = useRecordContext(); 
      return new Promise<any>((resolve, reject) => {
        var requestHeaders = new Headers();
        requestHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
        httpClient(`${apiUrl}/${resource}/csFindPrice_FaPiao`, {
          method: 'POST',
          // body: qs.stringify({ id }),
          headers: requestHeaders,
        }).then(({ json }) => {
          if (json.Code == 1) {
            notify('Success', { type: 'success' });
            refresh();
            resolve(json);
          } else {
            notify(json.Message, { type: 'error' });
            reject(json.Message);
          }
        }).catch((e: any) => {
          let { status, body, name } = e;
          if (status === 401) {
            localStorage.removeItem('token');
            redirect(`/Login`);
          }
          console.log('e', status, body, name);
          reject(e);
        })
      });
    }
  );

  return (
    <TabbedForm {...props} >
      <TabbedForm.Tab label="产品信息">
        <Stack sx={{ width: '100%' }} direction={'row'} spacing={2}>
          <Stack sx={{ flex: 3 }} >
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
              <ReferenceInput sx={{ flex: 1 }} source="Pro_BrandId" reference="Admin/CMSPro_Brand" perPage={999} >
                <SelectInput label="品牌" optionText="BrandName" validate={[required()]} />
              </ReferenceInput>
              <TextInput sx={{ flex: 1 }} label={useTxtData.table.Code} source="Code" validate={[required()]} />
              <NumberInput sx={{ flex: 1 }} label={useTxtData.table.MinAge} source="MinAge" step={1} />
              <NumberInput sx={{ flex: 1 }} label={useTxtData.table.MaxAge} source="MaxAge" step={1} />
            </Stack>

            <Stack direction={'row'} spacing={2} alignItems={'center'}>
              <TextInput sx={{ flex: 2 }} label={useTxtData.table.Name} source="Name" validate={[required()]} />
              <TextInput sx={{ flex: 3 }} label={useTxtData.table.Title} source="Title" />
            </Stack>

            <UpLoadFile multiple label={useTxtData.table.Pics} source="Pics" />
            <TextInput label={useTxtData.table.Tags} source="Tags" />

            <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ mb: 3 }}>
              <FunctionField sx={{ flex: 1, "& h1": { fontSize: 16, fontWeight: 'bold' }, "& th": { color: '#888' } }} render={({ Info1 }: any) => <div dangerouslySetInnerHTML={{ __html: Info1 }} />} />
              <FunctionField sx={{ flex: 1, "& h1": { fontSize: 16, fontWeight: 'bold' }, "& th": { color: '#888' } }} render={({ Info2 }: any) => <div dangerouslySetInnerHTML={{ __html: Info2 }} />} />
            </Stack>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
              <FunctionField sx={{ flex: 1, "& h1": { fontSize: 16, fontWeight: 'bold' }, "& th": { color: '#888' } }} render={({ Info3 }: any) => <div dangerouslySetInnerHTML={{ __html: Info3 }} />} />
              <FunctionField sx={{ flex: 1, "& h1": { fontSize: 16, fontWeight: 'bold' }, "& th": { color: '#888' } }} render={({ Info4 }: any) => <div dangerouslySetInnerHTML={{ __html: Info4 }} />} />
            </Stack>
            {/* <TextInput label={useTxtData.table.UpdateTime} source="UpdateTime" validate={[required()]} />
          <TextInput label={useTxtData.table.AtTime} source="AtTime" validate={[required()]} /> */}
          </Stack>
          <Stack sx={{ flex: 2 }}>
            <SelectArrayInput label='发布渠道' source="SaleQDs" choices={_PublishQuDaos()} />
            <UpLoadFile multiple label={useTxtData.table.Photos} source="Photos" />
            <UpLoadFile multiple label={useTxtData.table.DescPics} source="DescPics" />
            <TextInput multiline label={useTxtData.table.Desc} source="Desc" />
            <TextInput multiline label={"备注信息"} source="Remark" />
          </Stack>
        </Stack>
      </TabbedForm.Tab>
      <TabbedForm.Tab label="价格体系">
        <Stack direction={'row'} sx={{ width: '100%' }} >
          <Stack sx={{ flex: 1 }}>
            <Stack direction={'row'} alignItems={'center'} spacing={2}>
              <TextInput label={useTxtData.table.LingShouJia} source="LingShouJia" validate={[required()]} />
            </Stack>
            <Stack>
              <CeSuanPrice />
            </Stack>
            {/* <Stack direction={'row'}>
              <MButton onClick={() => csFindPrice_FaPiao()}>尝试采集供货价(发票清单)</MButton>
            </Stack> */}
            <ArrayInput label="价格" source="prices">
              <SimpleFormIterator inline disableClear getItemLabel={index => `#${index + 1}`}>
                <SelectInput sx={{ minWidth: 100 }} label="形式" source="PType" helperText={false} choices={PTypes} validate={[required()]} />
                <ReferenceInput source="Pro_SupplierId" reference="Admin/CMSPro_Supplier">
                  <SelectInput sx={{ minWidth: 100 }} label="供货" optionText={'SupplierName'} />
                </ReferenceInput>
                <ReferenceInput source="Pro_ChannelId" reference="Admin/CMSPro_Channel">
                  <SelectInput sx={{ minWidth: 100 }} label="采购" optionText={'ChannelName'} />
                </ReferenceInput>
                {/* <ReferenceInput source="ShopId" reference="Admin/CMSPro_Channel">
                <SelectInput label="店铺" optionText={'ChannelName'} />
              </ReferenceInput> */}
                <FormDataConsumer>
                  {({
                    formData, // The whole form data
                    scopedFormData, // The data for this item of the ArrayInput
                    getSource, // A function to get the valid source inside an ArrayInput
                    ...rest
                  }) => {
                    return <QDList getSource={getSource} />
                  }}
                </FormDataConsumer>
                <NumberInput sx={{ width: 100 }} label={'价格'} source="Price" />
                <BooleanInput label={'含税'} source="HasShui" />
                <NumberInput sx={{ width: 70 }} label={'运费'} source="YunFei" defaultValue={0} />
              </SimpleFormIterator>
            </ArrayInput>
          </Stack>
          <Stack>
            <FindPrice />
          </Stack>
        </Stack>
      </TabbedForm.Tab>
    </TabbedForm >
  );
}

const QDList = ({ getSource }: any) => {
  const resource = `Admin/CMSAdminDF_WdgjOrder`; //useResourceContext();
  const { data, isLoading, isSuccess, isError, error } = useQuery('QDList', () => {
    return httpClient(`${apiUrl}/${resource}/GetQDItems`, { method: 'POST' });
  }, { refetchOnWindowFocus: false });

  if (isLoading) return null;
  if (isSuccess) {
    let { Code, Result } = JSON.parse(data.body) as any;
    return <SelectInput sx={{ minWidth: 100 }} label="渠道" source={getSource('ShopId')} choices={Result} />
  }

  return null;
}