import * as React from 'react';
import { forwardRef } from 'react';
import { useState, useEffect } from 'react';
import { AppBar, UserMenu, MenuItemLink, useTranslate, useRedirect, useAuthProvider, Button, Logout, useNotify } from 'react-admin';
import qs from 'qs';
import { httpClient } from '../dataProvider';
import SettingsIcon from '@mui/icons-material/Settings';
import MailIcon from '@mui/icons-material/Mail';
import { makeStyles } from 'tss-react/mui';
import { Badge, IconButton, MenuItem, Typography, Button as MButton, Backdrop, CircularProgress } from '@mui/material';
import FontDownloadIcon from '@mui/icons-material/FontDownload';
import LogoutIcon from '@mui/icons-material/Logout';
import { useLogout } from 'react-admin';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { Stack } from '@mui/system';
import { useMutation } from 'react-query';
import { apiUrl } from '../../global';

const useStyles = makeStyles()((theme) => {
    return {
        title: {
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
        spacer: {
            flex: 1,
        },
    }
});

const ConfigurationMenu = forwardRef((props: any, ref: any) => {
    const logout = useLogout();
    return (
        <>
            <MenuItemLink
                ref={ref}
                to="/ChangePwd"
                primaryText={'修改密码'}
                leftIcon={<SettingsIcon />}
                onClick={props.onClick}
                sidebarIsOpen
            />
            {/* <MenuItemLink
                ref={ref}
                to="/login"
                primaryText={'退出'}
                leftIcon={<LogoutIcon />}
                onClick={() => logout()}

            /> */}
        </>
    );
});

const CustomUserMenu = (props: any) => (
    <UserMenu {...props}>
        <ConfigurationMenu />
        <Logout />
    </UserMenu>
);

const defaultProps = {
    color: 'error',
    children: <MailIcon />,
};

const MyAppBar = (props: any) => {
    const redirect = useRedirect();
    const authProvider = useAuthProvider();
    const [pendingCount, setPendingCount] = React.useState(0);
    const [permissions, setPermissions] = React.useState('');

    useEffect(() => {

    }, []);

    const { classes } = useStyles();
    return (
        <AppBar {...props} elevation={1} userMenu={<CustomUserMenu />}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <span className={classes.spacer} />
            <SMOrderDy />
            {/* {pendingCount > 0 &&
                <IconButton style={{ color: '#FFF' }} onClick={() => redirect(`/${permissions}/CMSSKOrder`)}>
                    <Badge badgeContent={pendingCount} max={999} {...defaultProps}>

                    </Badge>
                </IconButton>
            } */}
        </AppBar>
    );
};

const SMOrderDy = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        if (!open) GetShaoMa();
        setOpen(!open);
    };

    const [url, setUrl] = useState(null);
    const { mutate: GetShaoMa, isLoading } = useMutation(
        () => {
            return new Promise<any>((resolve, reject) => {
                var requestHeaders = new Headers();
                requestHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
                httpClient(`${apiUrl}/Admin/CMSDF_WdgjOrder/CreateQrcode`, {
                    method: 'POST',
                    body: qs.stringify({}),
                    headers: requestHeaders,
                }).then(({ json }) => {
                    console.log(json);
                    if (json.Code == 1) {
                        if (json.Result != null && json.Result.success === true) {
                            setUrl(json.Result.data.shortUrl);
                        }
                        resolve(json);
                    } else {
                        notify(json.Message, { type: 'error' });
                        reject(json.Message);
                    }
                }).catch((e: any) => {
                    let { status, body, name } = e;
                    if (status === 401) {
                        localStorage.removeItem('token');
                        redirect(`/Login`);
                    }
                    console.log('e', status, body, name);
                    reject(e);
                })
            });
        }
    );

    return (
        <>
            <MButton onClick={handleToggle} startIcon={<QrCodeIcon />} size='small' variant='text' sx={{ mr: 1, color: '#FFF', border: '1px solid #FFF' }}>
                订阅订单动态
            </MButton>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={handleClose}
            >
                {isLoading ? <CircularProgress color="inherit" />
                    :
                    <Stack alignItems={'center'} sx={{ overflow: 'hidden', borderRadius: 1, pt: 3, backgroundColor: '#FFF', color: '#333' }}>
                        <Typography variant='h6'>扫码随时关注订单动态</Typography>
                        <Typography variant='caption'>订单有动态时会推送消息到微信</Typography>
                        <img style={{ maxWidth: 300 }} src={url ?? ''} />
                    </Stack>
                }
            </Backdrop>
        </>
    )
}

export default MyAppBar;