import React, { useEffect, useState } from "react";
import { Button, Title, useLogout } from 'react-admin';
import { apiUrl, dealWuLiuCom } from '../global';
import { useNotify, useRefresh, useRedirect, useAuthProvider } from 'react-admin';
import { httpClient } from '../admin/dataProvider';
import { Card, CardContent, Chip, Container, Divider, Paper, Snackbar, TextField, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { fetchUtils, HttpError } from 'react-admin';
import { useParams } from "react-router-dom";
import { Stack } from "@mui/system";
import copy from 'copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const ShareJS = () => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const params = useParams();

    // console.log('params', params);
    const { Sign } = params;//'0D88AC2F326C3CE3E4D5A22FCAD5AB40';

    const { data, isLoading, isError, error } = useQuery('ShareJS', () => {
        return fetchUtils.fetchJson(`${apiUrl}/O/GetJSMessage?sign=${Sign}`, { method: 'POST' });
    }, { refetchOnWindowFocus: false });

    document.title = `Loading`;

    if (isLoading) return <div>loading</div>
    if (isError) return <div>Error: {(error as any).message}</div>

    let { Code, Message, Result } = JSON.parse(data!.body);
    if (Code != 1) return <div>{Message}</div>
    let { js, orders } = Result;
    let { CreateTime, JSNumber, JSStatus, ShopName, TotalPrice } = js;
    document.title = `结算单${JSNumber} - 灰犀牛`;

    return (
        <Container maxWidth="sm" sx={{
            minHeight: '100vh', position: 'relative', backgroundColor: '#2196f3', zIndex: 0
        }}>
            <Stack alignItems={'center'} sx={{ py: 3, minHeight: '100%', }}>
                <Stack justifyContent={'center'} alignItems={'center'} sx={{ borderRadius: 100, color: '#FFF', px: 5, py: 1 }}>
                    <Typography variant="h4">[{ShopName}] 结算单</Typography>
                    <Typography variant="h4">{JSNumber}</Typography>
                    <Typography sx={{ mt: 0, color: '#ffffff99' }} variant="caption">{CreateTime}</Typography>

                    <Typography variant="subtitle1" sx={{ opacity: 0.9, borderRadius: 100, mt: 1.5, px: 2 }}>{JSStatus} 共{orders.length}单</Typography>
                    <Typography variant="h4">¥{TotalPrice}</Typography>
                </Stack>


                {orders.map(({ WdgjOrderNumber, WdgjOrderStatus, CreateTime,
                    ReceiverName, ReceiverMobile,
                    Province, City, Area, Street, Address,
                    Pros,
                    WuLiuCompany, WuLiuNumber,
                    STotalPrice, SYunFei
                }: any, __index__: number) =>
                    <Paper elevation={3} sx={{ position: 'relative', width: '90%', borderRadius: 1, backgroundColor: '#fffffff5', px: 2, py: 2, mt: 3 }}>
                        <Chip size='small' sx={{ position: 'absolute', top: 0, right: 0, mr: 2, mt: 2 }} label={__index__ + 1} />
                        <Typography sx={{ fontWeight: 700, color: '#666666', mt: 0 }} variant="subtitle2">订单信息</Typography>
                        <Stack direction={'row'} sx={{ pl: 1 }} spacing={1}>
                            <Typography variant="subtitle2">{WdgjOrderNumber}</Typography><Typography variant="subtitle2">{WdgjOrderStatus}</Typography>
                        </Stack>
                        <Stack direction={'row'} sx={{ pl: 1 }} spacing={1}>
                            <Typography variant="subtitle2">{CreateTime}</Typography>
                        </Stack>
                        <Typography sx={{ fontWeight: 700, color: '#666666', mt: 1 }} variant="subtitle2">收件人信息：</Typography>
                        <Stack sx={{ pl: 1 }}>
                            <Typography variant="subtitle1">{ReceiverName} {ReceiverMobile}</Typography>
                            <Typography variant="subtitle2">{Province} {City} {Area} {Street}</Typography>
                            <Typography variant="subtitle2">{Address}</Typography>
                        </Stack>
                        <Typography sx={{ fontWeight: 700, color: '#666666', mt: 1 }} variant="subtitle2">货品摘要：</Typography>
                        <Stack sx={{ pl: 1 }}>
                            <Typography variant="subtitle2"><div dangerouslySetInnerHTML={{ __html: Pros.replace(/\r/g, '<br/>') }}></div></Typography>
                        </Stack>
                        <Typography sx={{ fontWeight: 700, color: '#666666', mt: 1 }} variant="subtitle2">物流信息</Typography>
                        <Stack direction={'row'} sx={{ pl: 1 }} spacing={1}>
                            <Typography variant="subtitle2">{dealWuLiuCom(WuLiuCompany)}</Typography><Typography variant="subtitle2">{WuLiuNumber}</Typography>
                        </Stack>
                        <Divider sx={{ pt: 1 }} />
                        <Stack sx={{ pt: 1, color: '#ff4700' }} direction={'row'} justifyContent={'space-between'}>
                            <Typography variant="subtitle2">价格：¥{STotalPrice}</Typography>
                            {SYunFei != null && SYunFei != 0 ? <Typography variant="subtitle2">运费：¥{SYunFei}</Typography> : <Typography variant="subtitle2">包邮</Typography>}
                        </Stack>
                    </Paper>
                )}
            </Stack>
        </Container >
    );
};


export default ShareJS;