
import React from 'react';
import { Login, LoginForm } from 'react-admin';
import { images } from './imageResource';
import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';

//生成从minNum到maxNum的随机数
function randomNum(minNum: number, maxNum: number) {
    switch (arguments.length) {
        case 1:
            return parseInt((Math.random() * minNum + 1).toString(), 10);
            break;
        case 2:
            return parseInt((Math.random() * (maxNum - minNum + 1) + minNum).toString(), 10);
            break;
        default:
            return 0;
            break;
    }
}
var i = randomNum(0, images.length - 1);
let backgroundImage = (process.env.NODE_ENV === 'development' ? "http://" : "https://") + "linju1020.gitee.io/randomimage" + '/images/' + images[i];

const MyComponent = styled('div')({
    "> div": {
        backgroundImage: `url(${backgroundImage})`,
    },
    '& .RaLogin-card': {
        backgroundColor: '#FFF',
        overflow: 'inherit'
    },

});

const MyLoginForm = LoginForm;

const MyLogin = (props: any) => (
    <MyComponent>
        <Login {...props}>
            <MyLoginForm />
            <Stack pt={2} justifyContent={'center'} alignItems={'center'} sx={{ position: 'relative' }}>
                <Typography variant='caption' sx={{ px: 1, color: '#FFF', backgroundColor: '#66666677', borderRadius: 1, position: 'absolute', bottom: -32 }}>
                    Copyright 2023 Scxiniu.com All Rights Reserved
                </Typography>
            </Stack>
        </Login>
    </MyComponent>
);

export default MyLogin;