import { Collapse, ListItemIcon, MenuItem, Tooltip, Typography, useMediaQuery } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { MenuItemLink, DashboardMenuItem, useTheme, useResourceDefinitions, useSidebarState } from 'react-admin';
import SettingsIcon from '@mui/icons-material/Settings';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SellIcon from '@mui/icons-material/Sell';
import PaidIcon from '@mui/icons-material/Paid';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import WindowIcon from '@mui/icons-material/Window';

const SubMenu = (props: any) => {
    const theme = useTheme();
    const [show, setShow] = useState(false);

    return <React.Fragment>
        <MenuItem dense={false} onClick={() => { setShow(!show) }}>
            {
                !props.open ?
                    <Tooltip title={props.title} placement="right">
                        <ListItemIcon style={{ minWidth: 40 }}>
                            {show ? <ExpandMoreIcon /> : props.leftIcon}
                        </ListItemIcon>
                    </Tooltip>
                    :
                    <ListItemIcon style={{ minWidth: 40 }}>
                        {show ? <ExpandMoreIcon /> : props.leftIcon}
                    </ListItemIcon>
            }
            <Typography color="textSecondary">
                {props.title}
            </Typography>
        </MenuItem>
        <Collapse in={show} timeout="auto" unmountOnExit>
            {props.children}
        </Collapse>
    </React.Fragment>
}

const Menu = ({ onMenuClick, logout }: any) => {

    const isXSmall = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));
    // const open = useSelector((state: any) => state.admin.ui.sidebarOpen);
    const [open] = useSidebarState();
    // const resources = useSelector(getResources);
    const resourcesDefinitions = useResourceDefinitions();
    const resources = Object.keys(resourcesDefinitions).map(name => resourcesDefinitions[name]);

    const props = { onMenuClick, logout, open };

    const groups = [
        { name: '销售订单', icon: <SellIcon /> },
        { name: '结算汇总', icon: <PaidIcon /> },
        { name: '数据汇总', icon: <SignalCellularAltIcon /> },
        { name: '产品数据', icon: <WindowIcon /> }
    ]

    return (
        <div>
            <DashboardMenuItem
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            {groups.map((group, index) => {
                let resArr = resources.filter((t: any) => t.options.group == group.name);
                if (resArr.length > 0)
                    return (
                        <SubMenu key={index} {...props} title={group.name} leftIcon={group.icon}>
                            {resArr.map((resource: any) => (
                                <MenuItemLink
                                    key={resource.name}
                                    to={`/${resource.name}`}
                                    primaryText={resource.options && resource.options.label || resource.name}
                                    leftIcon={resource.options && resource.options.icon}
                                    onClick={onMenuClick}
                                    sidebarIsOpen={open}
                                    sx={{ pl: open ? 4 : 3 }}
                                />
                            ))}
                        </SubMenu>)
                else
                    return null;
            })}

            {/* <MenuItemLink
                to="/custom-route"
                primaryText="Miscellaneous"
                leftIcon={<LabelIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            /> */}
            {isXSmall && logout}
        </div>
    );
};

export default Menu;