import * as React from 'react';
import qs from 'qs';
import { Fragment } from 'react';
import { makeStyles } from 'tss-react/mui';
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, Form, useResourceContext, useNotify, FunctionField, useRecordContext, useRefresh, DateInput, SimpleList, Pagination } from 'react-admin';
import {
  useListContext,
  useRedirect,
  List,
  Datagrid,
  Filter,
  Edit,
  Create,

  Toolbar,
  TopToolbar,
  DeleteWithConfirmButton,
  BulkDeleteWithConfirmButton,
  Show,
  SimpleShowLayout,

  ReferenceField,
  TextField,
  ImageField,
  BooleanField,

  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  NumberInput,
  BooleanInput,
  NullableBooleanInput,

  Button,
  EditButton,
  ShowButton,
  SaveButton,
} from 'react-admin';
import { useForm, useFormContext } from 'react-hook-form';
import { Button as MButton, Box, useMediaQuery, Stack, Chip, Paper, Divider } from '@mui/material';
import { PreviewImage, UpLoadFile } from './custom/UpLoadFile';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { useMutation } from 'react-query';
import { apiUrl } from '../../global';
import { httpClient } from '../dataProvider';
import { QDList } from './DF_WdgjOrder';
import moment from 'moment';
import copy from 'copy-to-clipboard';
//import ResetOrderNum from './WdgjOrder_ResetOrderNum';

{/* 
  import { WdgjOrderList, WdgjOrderCreate, WdgjOrderEdit, WdgjOrderShow } from './components/WdgjOrder';

  <Resource name="CMSWdgjOrder" list={WdgjOrderList} create={WdgjOrderCreate} edit={WdgjOrderEdit} show={WdgjOrderShow} />
  <Resource name="CMSWdgjOrder" list={ListGuesser} create={EditGuesser} edit={EditGuesser} edit={ShowGuesser} /> 
*/}

const useStyles = makeStyles()((theme) => {
  return {
    imageField: {
      '& img.RaImageField-image': { width: 60 }
    },
  };
});


const useTxtData = {
  page: {
    tableName: '订单记录',
  },
  table: {
    id: 'id',
    shopName: '渠道',
    warehouseName: 'warehouseName',
    mainPostid: '单号',
    goodslist: '货品',
    tradeStatusExplain: '状态',
    orderNo: '订单号',
    tradeNo: '网店管家单号',
    gmtCreate: '创建数据',
    logisticName: '快递',
    tradeId: 'tradeId',
    consignTime: '发货时间',
    state: 'state',
    city: 'city',
    district: 'district',
    town: 'town',
    address: 'address',
    receiverName: '收货人',
    mobile: '手机',
    sourceJson: 'sourceJson',
    AtTime: 'AtTime',
    Remark: '备注',
    ShouHouDan: 'ShouHouDan',
    QuXiao: 'QuXiao',
  }
};

//分页列表页面
export const WdgjOrderList = () => {
  const resource = useResourceContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  // 筛选器模块
  // const Filters = () => {
  //   return (
  //     <Filter>
  //       <TextInput label="ID" source="id" alwaysOn />
  //     </Filter>
  //   );
  // };

  const Filters = (props: any) => {
    const { displayedFilters, filterValues, setFilters, hideFilter, refetch } = useListContext();
    if (props.context === "button") return null;
    const onSubmit = (values: any) => { if (Object.keys(values).length > 0) { setFilters(values, displayedFilters); } else { setFilters({}, displayedFilters); } refetch(); };
    const resetFilter = () => { setFilters({}, displayedFilters); };
    return (
      <div>
        <Form onSubmit={onSubmit} defaultValues={filterValues}>
          <Stack direction={isSmall ? 'column' : 'row'} alignItems={isSmall ? 'stretch' : 'baseline'} spacing={isSmall ? 0 : 1.5}>
            <QDList />
            <TextInput label="关键词" source="Key" alwaysOn resettable />
            <DateInput label="创建时间 开始" source="StartTime" />
            <div> - </div>
            <DateInput label="结束" source="EndTime" />
            <BooleanInput label="含取消订单" source="AndCancel" defaultValue={false} />
            <MButton variant="contained" color="primary" type="submit" startIcon={<SearchIcon />}>搜索</MButton>
            <MButton variant="outlined" onClick={resetFilter} startIcon={<ClearAllIcon />}>重置</MButton>
          </Stack>
        </Form>
      </div>
    );
  };

  //批量操作模块
  const PostBulkActionButtons = () => {
    return (
      <Fragment>
        {/* <ResetOrderNum label="重置排序"  /> */}
        {/* <BulkDeleteWithConfirmButton confirmTitle="删除确认" confirmContent="确认要删除所选记录吗？" mutationMode='undoable' /> */}
      </Fragment>
    );
  };

  const { mutate: jiemaMobile, isLoading } = useMutation(
    (tradeNo: any) => {
      return new Promise<any>((resolve, reject) => {
        var requestHeaders = new Headers();
        requestHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
        httpClient(`${apiUrl}/${resource}/JiemaMobile`, {
          method: 'POST',
          body: qs.stringify({ tradeNo }),
          headers: requestHeaders,
        }).then(({ json }) => {
          console.log(json);
          if (json.Code == 1) {
            notify('解码手机号成功', { type: 'success' });
            refresh();
            resolve(json);
          } else {
            notify(json.Message, { type: 'error' });
            reject(json.Message);
          }
        }).catch((e: any) => {
          let { status, body, name } = e;
          if (status === 401) {
            localStorage.removeItem('token');
            redirect(`/Login`);
          }
          console.log('e', status, body, name);
          reject(e);
        })
      });
    }
  );
  //行右侧功能按钮
  const RowAction = () => {
    const record = useRecordContext();
    const { _realMobile } = record;
    return (
      <div style={{ textAlign: 'right' }}>
        {/* <EditButton /><br /> */}
        {/* <ShowButton /><br /> */}
        {/* <DeleteWithConfirmButton confirmTitle="删除确认" confirmContent="确认要删除该记录吗？" /> */}
        {!_realMobile &&
          <MButton onClick={() => {
            const { tradeNo } = record;
            jiemaMobile(tradeNo);
          }}>解码</MButton>
        }
      </div>
    );
  }

  const { mutate: copyMobile } = useMutation(
    ({ tradeNo, mobile }: any) => {

      copy(`${mobile}`);
      notify('复制成功\r\n' + mobile, { type: 'success' });

      return new Promise<any>((resolve, reject) => {
        var requestHeaders = new Headers();
        requestHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
        httpClient(`${apiUrl}/${resource}/CopyMobile`, {
          method: 'POST',
          body: qs.stringify({ tradeNo }),
          headers: requestHeaders,
        }).then(({ json }) => {
          console.log(json);
          if (json.Code == 1) {
            // notify('刷新成功', { type: 'success' });
            refresh();
            resolve(json);
          } else {
            notify(json.Message, { type: 'error' });
            reject(json.Message);
          }
        }).catch((e: any) => {
          let { status, body, name } = e;
          if (status === 401) {
            localStorage.removeItem('token');
            redirect(`/Login`);
          }
          console.log('e', status, body, name);
          reject(e);
        })
      });
    }
  );

  const MItem = ({ Title, Value, ...Props }: any) => {
    console.log('Value', Props)
    let VType = typeof (Value);
    return (
      <Stack {...Props} direction={'row'} alignItems={'center'} style={{ fontSize: 12 }}>
        <div style={{ color: '#666' }}>{Title}</div>
        <span style={{ flex: 1 }}></span>
        {VType == 'function' ?
          Value()
          :
          Value
        }
      </Stack>
    )
  }

  const { classes } = useStyles();
  return (
    <List
      title={useTxtData.page.tableName + '列表'}
      perPage={50}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<Filters />}
      exporter={false}
      sx={{ "& .MuiToolbar-root .MuiToolbar-root": { height: 0, minHeight: 0 }, "& .MuiToolbar-root > div:first-child": isSmall ? { width: '100%' } : {} }}
    >
      {!isSmall ?
        <Datagrid
          bulkActionButtons={false}
        >
          <FunctionField label={`id`} render={({ id, gmtCreate }: any) => <>
            <div>{id}</div>
            {gmtCreate && <div>{moment(gmtCreate).format("MM-DD HH:mm")}</div>}
          </>} />
          <FunctionField label={`订单`} render={({ tradeStatusExplain, tradeNo }: any) => <>
            <div>{tradeNo}</div>
            <div>{tradeStatusExplain}</div>
          </>} />
          <TextField label={useTxtData.table.shopName} source="shopName" sortable={false} />
          <FunctionField label={useTxtData.table.goodslist} render={({ goodslist, Remark }: any) => {
            return (
              <>
                {goodslist.split(',').map((goodStr: any) => <div>{goodStr}</div>)}
                {Remark && <div>{Remark}</div>}
              </>
            )
          }} />
          <FunctionField label={`收货人`} render={({ receiverName, state, city }: any) => <>
            <div>{receiverName}</div>
            <div>{state} {city}</div>
          </>} />
          <FunctionField label={useTxtData.table.mobile} render={({ tradeNo, mobile, _copyCount, _realMobile }: any) => <>
            {
              _realMobile ?
                <Stack direction={'row'} spacing={0.5}>
                  {_copyCount > 0 && <Chip size='small' color='warning' label={_copyCount} />}
                  <div onClick={() => {
                    copyMobile({ tradeNo, mobile: _realMobile });
                  }}>
                    <Chip size='small' color='secondary' label={_realMobile} />
                  </div>
                </Stack>
                : <div style={{ wordBreak: 'break-word', maxWidth: 400 }}>{mobile}</div>
            }
          </>} />
          <RowAction />
        </Datagrid>
        :
        <SimpleList
          linkType={false}
          sx={{ "& .MuiButtonBase-root": { p: 0, m: 0 } }}
          primaryText={({
            gmtCreate, tradeStatusExplain, tradeNo, shopName, goodslist, Remark, receiverName, state, city, mobile, _copyCount, _realMobile
          }: any) => {
            return (
              <Paper sx={{ p: 1 }} elevation={2}>
                {shopName &&
                  <MItem Title={'渠道'} Value={shopName} />
                }
                {tradeNo &&
                  <MItem Title={'单号'} Value={tradeNo} />
                }
                {tradeStatusExplain &&
                  <MItem Title={'状态'} Value={tradeStatusExplain} />
                }
                {
                  <MItem Title={'产品'} Value={() => <Stack alignItems={'flex-end'}>
                    {goodslist.split(',').map((goodStr: any) => <div>{goodStr}</div>)}
                    {Remark && <div style={{ color: 'red' }}>{Remark}</div>}
                  </Stack>} />
                }
                <MItem Title={'时间'} Value={gmtCreate} />
                <MItem Title={'收货信息'} Value={`${receiverName} ${state} ${city}`} />
                <MItem Title={'收货手机'} Value={() =>
                  <>
                    {
                      _realMobile ?
                        <Stack direction={'row'} spacing={0.5}>
                          {_copyCount > 0 && <Chip size='small' color='warning' label={_copyCount} />}
                          <div onClick={() => {
                            copyMobile({ tradeNo, mobile: _realMobile });
                          }}>
                            <Chip size='small' color='secondary' label={_realMobile} />
                          </div>
                        </Stack>
                        : <div style={{ wordBreak: 'break-word', maxWidth: 400 }}>{mobile}</div>
                    }
                  </>
                } />
                <Divider sx={{ my: 1 }} />
                <FunctionField render={(record: any) =>
                  <Stack direction={'row'} justifyContent={'flex-end'}>
                    {!_realMobile &&
                      <MButton onClick={() => {
                        const { tradeNo } = record;
                        jiemaMobile(tradeNo);
                      }}>解码</MButton>
                    }
                  </Stack>
                } />
              </Paper>
            )
          }}
        />
      }
    </List>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//SHOW页面
export const WdgjOrderShow = () => {
  const ShowActions = () => {
    const resource = useResourceContext();
    const redirect = useRedirect();
    return (
      <TopToolbar>
        <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text">
          <ArrowBackIcon />
        </Button>
        <EditButton />
      </TopToolbar>
    );
  };

  const { classes } = useStyles();
  return (
    <Show title={'查看' + useTxtData.page.tableName} actions={<ShowActions />}>
      <SimpleShowLayout>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        <TextField label={useTxtData.table.id} source="id" />
        <TextField label={useTxtData.table.shopName} source="shopName" />
        <TextField label={useTxtData.table.warehouseName} source="warehouseName" />
        <TextField label={useTxtData.table.mainPostid} source="mainPostid" />
        <TextField label={useTxtData.table.goodslist} source="goodslist" />
        <TextField label={useTxtData.table.tradeStatusExplain} source="tradeStatusExplain" />
        <TextField label={useTxtData.table.orderNo} source="orderNo" />
        <TextField label={useTxtData.table.tradeNo} source="tradeNo" />
        <TextField label={useTxtData.table.gmtCreate} source="gmtCreate" />
        <TextField label={useTxtData.table.logisticName} source="logisticName" />
        <TextField label={useTxtData.table.tradeId} source="tradeId" />
        <TextField label={useTxtData.table.consignTime} source="consignTime" />
        <TextField label={useTxtData.table.state} source="state" />
        <TextField label={useTxtData.table.city} source="city" />
        <TextField label={useTxtData.table.district} source="district" />
        <TextField label={useTxtData.table.town} source="town" />
        <TextField label={useTxtData.table.address} source="address" />
        <TextField label={useTxtData.table.receiverName} source="receiverName" />
        <TextField label={useTxtData.table.mobile} source="mobile" />
        <TextField label={useTxtData.table.sourceJson} source="sourceJson" />
        <TextField label={useTxtData.table.AtTime} source="AtTime" />
        <TextField label={useTxtData.table.Remark} source="Remark" />
        <TextField label={useTxtData.table.ShouHouDan} source="ShouHouDan" />
        <TextField label={useTxtData.table.QuXiao} source="QuXiao" />
      </SimpleShowLayout>
    </Show>
  );
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//编辑页面
export const WdgjOrderEdit = () => {
  const resource = useResourceContext();
  const redirect = useRedirect();

  const PostEditActions = () => {
    return (
      <TopToolbar >
        <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text">
          <ArrowBackIcon />
        </Button>
      </TopToolbar>
    );
  };
  const PostEditToolbar = () => {
    return (
      <Toolbar >
        <SaveButton />
      </Toolbar>
    );
  };
  return (
    <Edit redirect={`/${resource}`} title={'编辑' + useTxtData.page.tableName} actions={<PostEditActions />}>
      <MyForm Edit={true} toolbar={<PostEditToolbar />} />
    </Edit>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//创建页面
export const WdgjOrderCreate = () => {
  const resource = useResourceContext();
  const redirect = useRedirect();
  const notify = useNotify();

  const PostCreateActions = () => {
    return (
      <TopToolbar>
        <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text" >
          <ArrowBackIcon />
        </Button>
      </TopToolbar>
    );
  };
  const PostCreateToolbar = () => {
    const { reset, resetField, handleSubmit, setValue, getValues, } = useFormContext();
    return (
      <Toolbar>
        <SaveButton alwaysEnable label="保存&列表" />
        <SaveButton alwaysEnable type='button' label="保存&继续" variant="text" mutationOptions={{
          onSuccess: () => {
            notify('创建成功', { type: 'success' });
            reset();
          }
        }} />
      </Toolbar>
    );
  };

  return (
    <Create redirect={`/${resource}`} title={'新增' + useTxtData.page.tableName} actions={<PostCreateActions />}>
      <MyForm Create={true} toolbar={<PostCreateToolbar />} />
    </Create>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//Create和Edit公用表单
const MyForm = (props: any) => {
  let { Edit, Create } = props;
  return (
    <SimpleForm {...props} >
      {/* <TextInput source="Name" /> */}
      {/* <NumberInput source="OrderNum" /> */}
      <TextInput label={useTxtData.table.id} source="id" validate={[required()]} />
      <TextInput label={useTxtData.table.shopName} source="shopName" validate={[required()]} />
      <TextInput label={useTxtData.table.warehouseName} source="warehouseName" validate={[required()]} />
      <TextInput label={useTxtData.table.mainPostid} source="mainPostid" validate={[required()]} />
      <TextInput label={useTxtData.table.goodslist} source="goodslist" validate={[required()]} />
      <TextInput label={useTxtData.table.tradeStatusExplain} source="tradeStatusExplain" validate={[required()]} />
      <TextInput label={useTxtData.table.orderNo} source="orderNo" validate={[required()]} />
      <TextInput label={useTxtData.table.tradeNo} source="tradeNo" validate={[required()]} />
      <TextInput label={useTxtData.table.gmtCreate} source="gmtCreate" validate={[required()]} />
      <TextInput label={useTxtData.table.logisticName} source="logisticName" validate={[required()]} />
      <TextInput label={useTxtData.table.tradeId} source="tradeId" validate={[required()]} />
      <TextInput label={useTxtData.table.consignTime} source="consignTime" validate={[required()]} />
      <TextInput label={useTxtData.table.state} source="state" validate={[required()]} />
      <TextInput label={useTxtData.table.city} source="city" validate={[required()]} />
      <TextInput label={useTxtData.table.district} source="district" validate={[required()]} />
      <TextInput label={useTxtData.table.town} source="town" validate={[required()]} />
      <TextInput label={useTxtData.table.address} source="address" validate={[required()]} />
      <TextInput label={useTxtData.table.receiverName} source="receiverName" validate={[required()]} />
      <TextInput label={useTxtData.table.mobile} source="mobile" validate={[required()]} />
      <TextInput label={useTxtData.table.sourceJson} source="sourceJson" validate={[required()]} />
      <TextInput label={useTxtData.table.AtTime} source="AtTime" validate={[required()]} />
      <TextInput label={useTxtData.table.Remark} source="Remark" validate={[required()]} />
      <TextInput label={useTxtData.table.ShouHouDan} source="ShouHouDan" validate={[required()]} />
      <TextInput label={useTxtData.table.QuXiao} source="QuXiao" validate={[required()]} />
    </SimpleForm>
  );
}