import { Box, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, dividerClasses, IconButton, Link, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery, useTheme } from '@mui/material';
import * as React from 'react';
import { Fragment } from 'react';
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, useResourceContext, useResourceDefinitionContext, ArrayInput, SimpleFormIterator, useSaveContext, useNotify, FormDataConsumer, useSimpleFormIteratorItem, FunctionField, useRecordContext, useRefresh, Form, DateInput, SimpleList, fetchUtils } from 'react-admin';
import {
  useListContext,
  useRedirect,
  List,
  Datagrid,
  Filter,
  Edit,
  Create,

  Toolbar,
  TopToolbar,
  DeleteWithConfirmButton,
  BulkDeleteWithConfirmButton,
  Show,
  SimpleShowLayout,

  ReferenceField,
  TextField,
  ImageField,
  BooleanField,

  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  NumberInput,
  BooleanInput,
  NullableBooleanInput,

  Button,
  EditButton,
  ShowButton,
  SaveButton,
} from 'react-admin';
import { FormGroup as MFormGroup, FormControlLabel as MFormControlLabel, TextField as MTextField, Button as MButton, Switch as MSwitch } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { makeStyles } from 'tss-react/mui';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Stack } from '@mui/system';
import { useMutation, useQuery } from 'react-query';
import { httpClient } from '../dataProvider';
import { apiUrl, dealWuLiuCom, GetWdgjOrderStatusCodeColor, isDevelopment } from '../../global';
import qs from 'qs';
import { useForm, useFormContext } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import ShareIcon from '@mui/icons-material/Share';
import copy from 'copy-to-clipboard';
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { EditMemo, QDList } from './DF_WdgjOrder';
{/* 
  import { DF_WdgjOrderList, DF_WdgjOrderCreate, DF_WdgjOrderEdit, DF_WdgjOrderShow } from './components/DF_WdgjOrder';

  <Resource name="CMSDF_WdgjOrder" list={DF_WdgjOrderList} create={DF_WdgjOrderCreate} edit={DF_WdgjOrderEdit} show={DF_WdgjOrderShow} />
  <Resource name="CMSDF_WdgjOrder" list={ListGuesser} create={EditGuesser} edit={EditGuesser} edit={ShowGuesser} /> 
*/}

const useStyles = makeStyles()((theme) => {
  return {
    imageField: {
      '& img': { width: 60 }
    },
  };
});

const useTxtData = {
  page: {
    tableName: '销售单',
  },
  table: {
    id: 'id',
    AdminID: 'AdminID',
    ReceiverName: '收件人',
    ReceiverMobile: '手机',
    Province: '省',
    City: '市',
    Area: '区',
    Street: '街道',
    Address: '详细地址',
    Remark: '订单备注',
    CreateTime: '提交时间',
    ImportWdgjOrder: '接收订单',
    ImportWdgjOrderTime: '接收订单时间',
    WdgjOrderNumber: '订单号码',
    WdgjOrderStatus: '订单状态',
    GetWuLiu: '物流同步',
    GetWuLiuTime: '物流同步时间',
    WuLiuCompany: '物流公司',
    WuLiuNumber: '物流号码',
  }
};

//分页列表页面
export const DF_WdgjOrderList = () => {
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  // 筛选器模块
  // const Filters = () => {
  //   return (
  //     <Filter>
  //       <TextInput label="ID" source="id" alwaysOn />
  //     </Filter>
  //   );
  // };
  // const MobileFilters = [
  //   <TextInput label="关键词" source="Key" alwaysOn resettable />
  // ];
  const Filters = (props: any) => {
    const { displayedFilters, filterValues, setFilters, hideFilter, refetch } = useListContext();
    // if (isSmall) return null;
    if (props.context === "button") return null;
    const onSubmit = (values: any) => { if (Object.keys(values).length > 0) { setFilters(values, displayedFilters); } else { setFilters({}, displayedFilters); } refetch(); };
    const resetFilter = () => { setFilters({}, displayedFilters); };
    return (
      <div>
        <Form onSubmit={onSubmit} defaultValues={filterValues}>
          <Stack direction={isSmall ? 'column' : 'row'} alignItems={isSmall ? 'stretch' : 'baseline'} spacing={1.5}>
            <QDList />
            <TextInput label="关键词" source="Key" alwaysOn resettable helperText={false} />
            <DateInput label="创建时间 开始" source="StartTime" helperText={false} />
            {!isSmall && <div> - </div>}
            <DateInput label="结束" source="EndTime" helperText={false} />
            <BooleanInput label="含取消订单" source="AndCancel" defaultValue={false} helperText={false} />
            <MButton variant="contained" color="primary" type="submit" startIcon={<SearchIcon />} sx={{ mb: isSmall ? 1 : 0 }}>搜索</MButton>
            <MButton variant="outlined" onClick={resetFilter} startIcon={<ClearAllIcon />}>重置</MButton>
          </Stack>
        </Form>
      </div>
    );
  };

  //批量操作模块
  const AssetBulkActionButtons = () => {
    return (
      <Fragment>
        {/* <ResetOrderNum label="重置排序"  /> */}
        {/* <BulkDeleteWithConfirmButton confirmTitle="删除确认" confirmContent="确认要删除所选记录吗？" mutationMode='undoable' /> */}
      </Fragment>
    );
  };
  //行右侧功能按钮
  const RowAction = () => {
    const record = useRecordContext();
    let { ImportWdgjOrder, WdgjOrderNumber } = record;
    return (
      <div style={{ textAlign: 'right' }}>
        {/* <EditButton /><br /> */}
        {/* <ShowButton /><br /> */}
        {!ImportWdgjOrder ?
          <DeleteWithConfirmButton confirmTitle="删除确认" confirmContent="确认要删除该记录吗？" />
          :
          <>
            <IconButton aria-label="刷新">
              <RefreshIcon color='primary' fontSize="inherit" onClick={() => RefreshWdgjOrder(WdgjOrderNumber)} />
            </IconButton>
            <IconButton aria-label="分享">
              <ShareIcon color='warning' fontSize="inherit" onClick={() => ShareWdgjOrder(record)} />
            </IconButton>
          </>
        }
      </div>
    );
  }

  const resource = useResourceContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const redirect = useRedirect();

  const ShareWdgjOrder = ({ WuLiuCompany, WuLiuNumber, GetWuLiu, ShareSign, WdgjOrderNumber, ReceiverName, ReceiverMobile, Province, City, Area, Street, Address, Pros }: any) => {
    let txt = `订单号码：${WdgjOrderNumber}\r\n`;
    txt += `收货信息：${ReceiverName} ${ReceiverMobile}\r\n`;
    txt += `收货地址：${Province}${City}${Area}${Street}${Address}\r\n`;
    txt += `货品摘要：${Pros}\r\n`;

    if (GetWuLiu) {
      txt += `快递信息：${dealWuLiuCom(WuLiuCompany)} ${WuLiuNumber}\r\n`;
    }

    // let url = `跟踪链接：https://qd.scxiniu.com/#/shareorder/${ShareSign}`;
    var url = `跟踪链接：https://qd.scxiniu.com/shareorder2.html?Sign=${ShareSign}`;
    copy(txt + url);
    notify('复制成功\r\n' + txt + url, { type: 'success' });
  }

  const { mutate: RefreshWdgjOrder, isLoading } = useMutation(
    (WdgjOrderNumber: any) => {
      return new Promise<any>((resolve, reject) => {
        var requestHeaders = new Headers();
        requestHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
        httpClient(`${apiUrl}/Admin/CMSDF_WdgjOrder/RefreshWdgjOrder`, {
          method: 'POST',
          body: qs.stringify({ WdgjOrderNumber }),
          headers: requestHeaders,
        }).then(({ json }) => {
          console.log(json);
          if (json.Code == 1) {
            notify('刷新成功', { type: 'success' });
            refresh();
            resolve(json);
          } else {
            notify(json.Message, { type: 'error' });
            reject(json.Message);
          }
        }).catch((e: any) => {
          let { status, body, name } = e;
          if (status === 401) {
            localStorage.removeItem('token');
            redirect(`/Login`);
          }
          console.log('e', status, body, name);
          reject(e);
        })
      });
    }
  );

  const { classes } = useStyles();

  const MItem = ({ Title, Value, ...Props }: any) => {
    console.log('Value', Props)
    let VType = typeof (Value);
    return (
      <Stack {...Props} direction={'row'} alignItems={'center'} style={{ fontSize: 12 }}>
        <div style={{ color: '#666' }}>{Title}</div>
        <span style={{ flex: 1 }}></span>
        {VType == 'function' ?
          Value()
          :
          Value
        }
      </Stack>
    )
  }
  return (
    <List
      title={useTxtData.page.tableName + '列表'}
      sort={{ field: 'CreateTime', order: 'DESC' }}
      filters={<Filters />}
      exporter={false}
      sx={{ "& .MuiToolbar-root .MuiToolbar-root": { height: 0, minHeight: 0 }, "& .MuiToolbar-root > div:first-child": isSmall ? { width: '94vw' } : {} }}
    >
      {true //!isSmall 
        ?
        <Datagrid bulkActionButtons={false}>
          {/* <TextField source="id" /> */}
          {/* <TextField label="名称" source="Name" /> */}
          {/* <TextField label="排序" source="OrderNum" />  */}
          {/* <TextField label={useTxtData.table.id} source="id" sortable={false} /> */}
          {/* <TextField label={useTxtData.table.AdminID} source="AdminID" /> */}

          {/* <TextField sx={{ minWidth: 30, display: 'block' }} label="渠道" source="ShopName" sortable={false} /> */}
          {/* <FunctionField label='序号' render={({ __index__, Tag, CreateTime, Remark, __OrderSource }: any) => {
            const daoru = __OrderSource === 'f_wdgj';
            return (
              <>
                <Chip size='small' sx={{ mr: 1 }} label={__index__ + 1} />
                <div>
                  {daoru && <Chip size='small' color='warning' label={'导'} />}
                </div>
              </>
            )
          }} /> */}

          <FunctionField label='时间/备注' render={({ __index__, ShopName, Tag, CreateTime, Remark, __OrderSource }: any) => {
            const daoru = __OrderSource === 'f_wdgj';
            return (
              <>
                <Chip size='small' sx={{ mr: 1 }} label={__index__ + 1} />
                <div>{ShopName}</div>
                <div>
                  {Tag && <Chip size='small' color='warning' label={Tag} />}
                  {daoru && <span style={{ backgroundColor: 'red', color: '#FFF', padding: '0 1px' }}>导</span>}
                </div>
                {CreateTime && <div>{moment(CreateTime).format("MM-DD HH:mm")}</div>}
                {Remark && <Typography sx={{ color: '#FD5052' }} variant='caption'>{`备注: ${Remark}`}</Typography>}
              </>
            )
          }} />

          <FunctionField label='结算' render={({ id, STotalPrice, SYunFei, JSNumber, JSStatus, JS_TuiKuan }: any) => {
            return <SetJS {...{ id, STotalPrice, SYunFei, JSNumber, JSStatus, JS_TuiKuan }} />
          }} />

          <FunctionField label='供价/运费' render={({ id, STotalPrice, SYunFei, SPriceRemark }: any) => {
            return (
              <div style={{ minWidth: 80 }}>
                {
                  (STotalPrice == null && SYunFei == null) ?
                    <>
                      <SetPrice key={'p-' + id} txt={'设置'} oid={id} STotalPrice={STotalPrice} SYunFei={SYunFei} SPriceRemark={SPriceRemark} />
                      <RefreshPrice txt={'刷新'} oid={id} />
                    </>
                    :
                    <Stack alignItems={'start'}>
                      <SetPrice key={'p2-' + id} txt={'修改'} oid={id} STotalPrice={STotalPrice} SYunFei={SYunFei} SPriceRemark={SPriceRemark} />
                      <RefreshPrice txt={'刷新'} oid={id} />
                      <div>
                        {STotalPrice != 0 && <div>{`供 ¥${parseFloat(STotalPrice).toFixed(2)}`}</div>}
                        {SYunFei != null && SYunFei != 0 && <div>{`运 ¥${SYunFei}`}</div>}
                      </div>
                    </Stack>
                }
                <>
                  {
                    <Typography sx={{ color: '#FD5052' }} variant='caption'>{SPriceRemark && <span>{`备注: ${SPriceRemark}`}</span>}</Typography>
                  }
                </>
              </div>
            )
          }} />
          {/* <FunctionField label='时间/备注' render={({ CreateTime, Remark }: any) => {
            return (
              <>
                <div>{moment(CreateTime).format("MM-DD HH:mm")}</div>
                <Typography sx={{ color: '#FD5052' }} variant='caption'>{Remark && <span>{`备注: ${Remark}`}</span>}</Typography>
              </>
            )
          }} /> */}
          {/* <TextField label={useTxtData.table.CreateTime} source="CreateTime" /> */}

          {/* <TextField label={useTxtData.table.ReceiverName} source="ReceiverName" />
        <TextField label={useTxtData.table.ReceiverMobile} source="ReceiverMobile" />
        <TextField label={useTxtData.table.Province} source="Province" />
        <TextField label={useTxtData.table.City} source="City" />
        <TextField label={useTxtData.table.Area} source="Area" />
        <TextField label={useTxtData.table.Street} source="Street" />
        <TextField label={useTxtData.table.Address} source="Address" /> */}
          <FunctionField label='收件人信息/备注' render={({ id, ReceiverName, ReceiverMobile, Province, City, Area, Street, Address, WdgjOrderRemark }: any) => {
            // console.log('record', record)
            return (
              <Typography variant='subtitle2'>
                <div>{`${ReceiverName} ${ReceiverMobile}`}</div>
                <div>{`${Province} ${City} ${Area} ${Street}`}</div>
                <div>{`${Address}`}</div>
                <Stack direction={'row'} alignItems={'center'}>
                  <EditMemo id={id} />
                  {WdgjOrderRemark && <Typography sx={{ color: '#FD5052' }} variant='caption'>{`Erp备注: ${WdgjOrderRemark}`}</Typography>}
                </Stack>
              </Typography>
            )
          }} />

          <FunctionField label='货品信息' render={({ ProList: proItems }: any) => {
            // console.log('record', record)
            // let proItems = Pros.split('\r\n');
            return (
              <Stack spacing={0.3} alignItems={'flex-start'}>
                {proItems.map(({ id, Code, Title, Count, df_Price, df_YunFei, IsFlaw, BrandName }: any, index: number) =>
                  <Stack direction={'row'} alignItems={'flex-start'}>
                    <SetProPrice key={`p-${id}`} txt={`¥${df_Price}`} pid={id} title={`${Code} ${Title}`} isFlaw={IsFlaw} price={df_Price} yunfei={df_YunFei ?? 0} />
                    <Chip label={<>{IsFlaw === true ? <span style={{ backgroundColor: 'red', color: '#FFF', padding: '0 1px' }}>旧</span> : null} {`${BrandName ?? ''} ${Code} ${Title} * ${Count}`}</>} size="small" sx={{ height: 'auto', '& .MuiChip-label': { display: 'block', whiteSpace: 'normal', }, }} />
                  </Stack>
                )}
              </Stack>
            )
          }} />

          {/* <FunctionField label='系统状态' render={({ ImportWdgjError, ImportWdgjErrorMsg, ImportWdgjOrder, ImportWdgjOrderTime }: any) => {
            // console.log('record', record)
            if (ImportWdgjError) {
              return (
                <>
                  <div style={{ color: 'red' }}>创建异常</div>
                  <div style={{ maxWidth: 100, wordBreak: 'break-all' }}>{ImportWdgjErrorMsg}</div>
                </>
              )
            }
            else
              return (
                <>
                  {ImportWdgjOrderTime && <div>{moment(ImportWdgjOrderTime).format("MM-DD HH:mm")}</div>}
                  <div>{ImportWdgjOrder ? <Chip sx={{ backgroundColor: '#6EC4AE', color: '#FFF' }} label="已入系统" size="small" /> : ''}</div>
                </>
              )
          }} /> */}

          <FunctionField label='订单状态' render={({ GetWuLiu, WdgjOrderStatusCode, WdgjOrderStatus, WdgjOrderNumber }: any) => {
            var color = GetWdgjOrderStatusCodeColor(WdgjOrderStatusCode);
            return (
              <>
                {WdgjOrderNumber &&
                  <>
                    <div>{WdgjOrderNumber}</div>
                    <Stack direction={'row'} alignItems={'center'}>
                      <Chip label={WdgjOrderStatus} size="small" sx={{ color: '#FFF', backgroundColor: color }} />
                    </Stack>
                  </>
                }
              </>
            )
          }} />

          {/* <FunctionField label='物流状态' render={({ ImportWdgjOrder, GetWuLiu, GetWuLiuTime }: any) => {
            return (
              <>
                {ImportWdgjOrder &&
                  <>
                    {GetWuLiuTime && <div>{moment(GetWuLiuTime).format("MM-DD HH:mm")}</div>}
                    <div>{GetWuLiu ? <Chip sx={{ backgroundColor: '#14492B', color: '#FFF' }} label="已出单号" size="small" /> : ''}</div>
                  </>
                }
              </>
            )
          }} /> */}

          <FunctionField label='物流信息' render={({ GetWuLiu, WuLiuCompany, WuLiuNumber }: any) => {

            let nkd = dealWuLiuCom(WuLiuCompany);

            return (
              <>
                {GetWuLiu &&
                  <span onClick={() => {
                    let _m = `${nkd} ${WuLiuNumber}`;
                    copy(_m); notify(_m, { type: 'success' });
                  }}>
                    <div>{nkd}</div>
                    <Chip label={WuLiuNumber} size="small" />
                  </span>
                }
              </>
            )
          }} />

          <RowAction />
        </Datagrid>
        :
        <SimpleList
          linkType={false}
          sx={{ "& .MuiButtonBase-root": { p: 0, m: 0 } }}
          primaryText={({
            CreateTime, Remark,
            ReceiverName, ReceiverMobile, Province, City, Area, Street, Address,
            Pros,
            ImportWdgjError, ImportWdgjErrorMsg, ImportWdgjOrder, ImportWdgjOrderTime,
            WdgjOrderStatusCode, WdgjOrderStatus, WdgjOrderNumber,
            GetWuLiu, GetWuLiuTime,
            WuLiuCompany, WuLiuNumber
          }: any) => {
            let proItems = Pros.split('\r\n');
            return (
              <Paper sx={{ p: 1 }} elevation={2}>
                <MItem Title={'创建时间'} Value={CreateTime} />
                {Remark && <MItem Title={'备注'} Value={Remark} />}

                <MItem Title={'收货信息'} Value={`${ReceiverName} ${ReceiverMobile}`} />
                <MItem Title={'收货地址'} Value={`${Province} ${City} ${Area} ${Street}`} />
                <MItem Title={''} Value={`${Address}}`} />

                <MItem sx={{ py: 1 }} Title={'货品信息'} Value={() => {
                  return <Stack alignItems={'flex-end'}>
                    {proItems.map((promsg: any, index: number) => <div>{promsg}</div>)}
                  </Stack>
                }} />

                <MItem Title={'系统状态'} Value={() => {
                  if (ImportWdgjError) {
                    return <div style={{ wordBreak: 'break-all' }}>创建异常:{ImportWdgjErrorMsg}</div>
                  } else {
                    return <Stack direction={'row'} alignItems={'center'} spacing={1}>
                      {ImportWdgjOrderTime && <div>{ImportWdgjOrderTime}</div>}
                      <div>{ImportWdgjOrder ? <Chip sx={{ backgroundColor: '#6EC4AE', color: '#FFF' }} label="已入系统" size="small" /> : ''}</div>
                    </Stack>
                  }
                }} />
                <MItem sx={{ py: 1 }} Title={'订单状态'} Value={() => {
                  var color = GetWdgjOrderStatusCodeColor(WdgjOrderStatusCode);
                  return (
                    <>
                      {WdgjOrderNumber &&
                        <>
                          <div>{WdgjOrderNumber}</div>
                          <Stack direction={'row'} alignItems={'center'} sx={{ pl: 1 }}>
                            <Chip label={WdgjOrderStatus} size="small" sx={{ color: '#FFF', backgroundColor: color }} />
                          </Stack>
                        </>
                      }
                    </>
                  )
                }} />
                <MItem sx={{}} Title={'物流状态'} Value={() => {
                  return (
                    <>
                      {ImportWdgjOrder &&
                        <>
                          {GetWuLiuTime && <div>{GetWuLiuTime}</div>}
                          <Stack sx={{ pl: 1 }}>{GetWuLiu ? <Chip sx={{ backgroundColor: '#14492B', color: '#FFF' }} label="已出单号" size="small" /> : ''}</Stack>
                        </>
                      }
                    </>
                  )
                }} />
                <MItem sx={{ py: 1 }} Title={'物流信息'} Value={() => {
                  let nkd = dealWuLiuCom(WuLiuCompany);
                  return (
                    <>
                      {GetWuLiu &&
                        <span onClick={() => {
                          let _m = `${nkd} ${WuLiuNumber}`;
                          copy(_m); notify(_m, { type: 'success' });
                        }}>
                          <div>{nkd}</div>
                          <Chip sx={{ ml: 1 }} label={WuLiuNumber} size="small" />
                        </span>
                      }
                    </>
                  )
                }} />
                <Divider sx={{ my: 1 }} />
                <FunctionField render={(record: any) =>
                  <Stack direction={'row'} justifyContent={'flex-end'}>
                    {!ImportWdgjOrder ?
                      <DeleteWithConfirmButton confirmTitle="删除确认" confirmContent="确认要删除该记录吗？" />
                      :
                      <>
                        <IconButton aria-label="刷新" style={{ marginRight: 8 }}>
                          <RefreshIcon color='primary' fontSize="inherit" onClick={() => RefreshWdgjOrder(WdgjOrderNumber)} />
                        </IconButton>
                        <IconButton aria-label="分享" style={{ marginRight: 8 }}>
                          <ShareIcon color='warning' fontSize="inherit" onClick={() => ShareWdgjOrder(record)} />
                        </IconButton>
                      </>
                    }
                  </Stack>
                } />
              </Paper>
            )
          }}
        />
      }
    </List>
  );
};

const SetPrice = ({ txt, oid, STotalPrice = null, SYunFei = null, SPriceRemark = null }: any) => {
  const theme = useTheme();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const notify = useNotify();
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  const resource = useResourceContext();
  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  const [open, setOpen] = React.useState(false);
  const onSubmit = ({ price, yunfei, remark, savePrice }: any) => {
    setPrice({ price, yunfei, remark, savePrice })
  }
  const { mutate: setPrice, isLoading } = useMutation(
    (params: any) => {
      return new Promise<any>((resolve, reject) => {
        var requestHeaders = new Headers();
        requestHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
        httpClient(`${apiUrl}/${resource}/SavePrice`, {
          method: 'POST',
          body: qs.stringify({ oid, ...params }),
          headers: requestHeaders,
        }).then(({ json }) => {
          console.log('json', json);
          if (json.Code == 1) {
            notify(`保存成功`, { type: 'success' });
            setOpen(false);
            refresh();
            resolve(json.Result);
          } else {
            notify(`保存失败:${json.Message}`, { type: 'error' });
            resolve(json.Message);
          }
        }).catch((e: any) => {
          let { status, body, name } = e;
          if (status === 401) {
            localStorage.removeItem('token');
            redirect(`/Login`);
          }
          console.log('e', status, body, name);
          reject(e);
        })

      });
    }
  );
  // console.log('theme', theme)
  return (
    <Stack>
      <div style={{ color: theme.palette.primary.main }} onClick={() => setOpen(true)} >{txt}</div>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth={'sm'}>
        <DialogTitle>设置供货价格等信息</DialogTitle>
        <DialogContent>
          <MTextField autoFocus fullWidth label="供货价格" variant="outlined" {...register('price')} defaultValue={STotalPrice} />
          <MTextField fullWidth label="订单运费" variant="outlined" {...register('yunfei')} defaultValue={SYunFei} />
          <MTextField fullWidth label="订单备注" variant="outlined" {...register('remark')} defaultValue={SPriceRemark} />
          <MFormGroup>
            <MFormControlLabel control={<MSwitch {...register('savePrice')} />} label="保存价格进数据库" />
          </MFormGroup>
        </DialogContent>
        <DialogActions>
          <MButton color='error' onClick={() => setOpen(false)}>取消</MButton>
          <LoadingButton
            sx={{ width: 100 }}
            loading={isLoading}
            loadingIndicator="保存中."
            onClick={handleSubmit(onSubmit)}
          >确定</LoadingButton>
        </DialogActions>
      </Dialog>
    </Stack >
  )
}

const SetProPrice = ({ txt, title, pid, isFlaw, price, yunfei }: any) => {

  const theme = useTheme();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const notify = useNotify();
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  const resource = useResourceContext();
  const { register, handleSubmit, watch, formState: { errors } } = useForm({
    defaultValues: {
      price,
      yunfei,
      isFlaw,
      savePrice: false,
    }
  });

  // console.log(txt, title, pid, isFlaw, price)
  // console.log('_IsFlaw', isFlaw, watch('isFlaw'))

  const [open, setOpen] = React.useState(false);
  const onSubmit = ({ price, yunfei, savePrice, isFlaw }: any) => {
    // console.log("aaa", savePrice, isFlaw)
    setPrice({ price, yunfei, savePrice, isFlaw })
  }
  const { mutate: setPrice, isLoading } = useMutation(
    (params: any) => {
      return new Promise<any>((resolve, reject) => {
        var requestHeaders = new Headers();
        requestHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
        httpClient(`${apiUrl}/${resource}/SaveOrderProPrice`, {
          method: 'POST',
          body: qs.stringify({ pid, ...params }),
          headers: requestHeaders,
        }).then(({ json }) => {
          console.log('json', json);
          if (json.Code == 1) {
            notify(`保存成功`, { type: 'success' });
            setOpen(false);
            refresh();
            resolve(json.Result);
          } else {
            notify(`保存失败`, { type: 'error' });
            resolve(json.Message);
          }
        }).catch((e: any) => {
          let { status, body, name } = e;
          if (status === 401) {
            localStorage.removeItem('token');
            redirect(`/Login`);
          }
          console.log('e', status, body, name);
          reject(e);
        })

      });
    }
  );
  // console.log('theme', theme)
  return (
    <Stack>
      <Chip color='primary' label={txt} size="small" sx={{ height: 'auto', '& .MuiChip-label': { display: 'block', whiteSpace: 'normal', }, }} onClick={() => setOpen(true)} />
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth={'sm'}>
        <DialogTitle>设置单个货品供货价格信息</DialogTitle>
        <DialogContent>
          <DialogContentText>{title}</DialogContentText>
          <MTextField autoFocus fullWidth label="供货价格(单价)" variant="outlined" {...register('price')} />
          <MTextField fullWidth label="运费(单价)" variant="outlined" {...register('yunfei')} />
          <MFormGroup>
            <MFormControlLabel control={<MSwitch {...register('isFlaw')} checked={watch('isFlaw')} />} label="是否瑕疵品" />
          </MFormGroup>
          <MFormGroup>
            <MFormControlLabel control={<MSwitch {...register('savePrice')} checked={watch('savePrice')} />} label="保存价格进数据库" />
          </MFormGroup>
        </DialogContent>
        <DialogActions>
          <MButton color='error' onClick={() => setOpen(false)}>取消</MButton>
          <LoadingButton
            sx={{ width: 100 }}
            loading={isLoading}
            loadingIndicator="保存中."
            onClick={handleSubmit(onSubmit)}
          >确定</LoadingButton>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}

const RefreshPrice = ({ txt, oid }: any) => {
  const theme = useTheme();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const notify = useNotify();
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  const resource = useResourceContext();
  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  const { mutate: setPrice, isLoading } = useMutation(
    (params: any) => {
      return new Promise<any>((resolve, reject) => {
        var requestHeaders = new Headers();
        requestHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
        httpClient(`${apiUrl}/${resource}/RefreshPrice`, {
          method: 'POST',
          body: qs.stringify({ oid, ...params }),
          headers: requestHeaders,
        }).then(({ json }) => {
          console.log('json', json);
          if (json.Code == 1) {
            notify(`刷新成功`, { type: 'success' });
            refresh();
            resolve(json.Result);
          } else {
            notify(`刷新失败`, { type: 'error' });
            resolve(json.Message);
          }
        }).catch((e: any) => {
          let { status, body, name } = e;
          if (status === 401) {
            localStorage.removeItem('token');
            redirect(`/Login`);
          }
          console.log('e', status, body, name);
          reject(e);
        })

      });
    }
  );
  // console.log('theme', theme)
  return (
    <Stack>
      <div style={{ color: theme.palette.primary.main }} onClick={() => setPrice({})} >{txt}</div>
    </Stack >
  )
}
//

const SetJS = ({ id, STotalPrice, SYunFei, JSNumber, JSStatus, JS_TuiKuan }: any) => {
  const theme = useTheme();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const notify = useNotify();
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  const resource = useResourceContext();

  const { mutate: setJS, isLoading } = useMutation(
    (params: any) => {
      return new Promise<any>((resolve, reject) => {
        var requestHeaders = new Headers();
        requestHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
        httpClient(`${apiUrl}/${resource}/SetJS`, {
          method: 'POST',
          body: qs.stringify({ id }),
          headers: requestHeaders,
        }).then(({ json }) => {
          console.log('json', json);
          if (json.Code == 1) {
            notify(`设置成功`, { type: 'success' });
            refresh();
            resolve(json.Result);
          } else {
            notify(`设置失败 ${json.Message}`, { type: 'error' });
            resolve(json.Message);
          }
        }).catch((e: any) => {
          let { status, body, name } = e;
          if (status === 401) {
            localStorage.removeItem('token');
            redirect(`/Login`);
          }
          console.log('e', status, body, name);
          reject(e);
        })
      });
    }
  );

  return (
    <>
      {JSNumber ?
        <Stack alignItems={'center'}>
          <div>{JSNumber}</div>
          <div>{JSStatus}</div>
          {JSStatus != '已结算' &&
            <IconButton disabled={isLoading} onClick={() => setJS({})} color='error'>
              {isLoading ? <ScheduleIcon /> : <ClearIcon />}</IconButton>
          }
        </Stack>
        :
        <Stack alignItems={'center'}>
          {(STotalPrice == null || SYunFei == null) ? null :
            <IconButton disabled={isLoading} onClick={() => setJS({})} color='primary'>
              {isLoading ? <ScheduleIcon /> : <AddIcon />}</IconButton>
          }
        </Stack>
      }
      {JS_TuiKuan === true &&
        <Chip size='small' label={`已退款`} color='error' />
      }
    </>
  )
}