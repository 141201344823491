import React, { useEffect, useState } from "react";
import { Button, Title, useLogout } from 'react-admin';
import { GetWdgjOrderStatusCodeColor, apiUrl, dealWuLiuCom } from '../global';
import { useNotify, useRefresh, useRedirect, useAuthProvider } from 'react-admin';
import { httpClient } from '../admin/dataProvider';
import { Card, CardContent, Container, Paper, Snackbar, TextField, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { fetchUtils, HttpError } from 'react-admin';
import { useParams } from "react-router-dom";
import { Stack } from "@mui/system";
import copy from 'copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const ShareOrder = () => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const params = useParams();

    // console.log('params', params);
    const { Sign } = params;//'0D88AC2F326C3CE3E4D5A22FCAD5AB40';

    const [open, setOpen] = React.useState(false);
    const [msg, setMsg] = React.useState('');

    const { data, isLoading, isError, error } = useQuery('ShareOrder', () => {
        return fetchUtils.fetchJson(`${apiUrl}/O/GetOrderMessage?sign=${Sign}`, { method: 'POST' });
    }, { refetchOnWindowFocus: false });

    document.title = `Loading`;

    if (isLoading) return <div>loading</div>
    if (isError) return <div>Error: {(error as any).message}</div>

    let { Code, Message, Result } = JSON.parse(data!.body);
    if (Code != 1) return <div>{Message}</div>
    let {
        CreateTime,
        ImportWdgjOrder, ImportWdgjOrderTime, WdgjOrderNumber, WdgjOrderStatus, WdgjOrderStatusCode,
        ReceiverName, ReceiverMobile, Province, City, Area, Street, Address,
        GetWuLiu, GetWuLiuTime, WuLiuCompany, WuLiuNumber,
        ProsStr,
        Ma,
    } = Result;
    // console.log('json', json);
    if (!ImportWdgjOrder)
        return <div>订单正在处理中，请稍等片刻</div>

    document.title = `订单${WdgjOrderNumber} - 灰犀牛`;

    let nkd = dealWuLiuCom(WuLiuCompany);
    var color = GetWdgjOrderStatusCodeColor(WdgjOrderStatusCode);

    return (
        <Container maxWidth="sm" sx={{
            minHeight: '100vh', position: 'relative', backgroundColor: '#2196f3', zIndex: 0,
            // "&::before": {
            //     content: '""', position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, zIndex: -1,
            //     backgroundImage: 'url(https://s1.ax1x.com/2023/03/24/pp0DOFf.jpg)',
            //     backgroundSize: 'cover', opacity: 0.4
            // }
        }}>
            <Stack alignItems={'center'} sx={{ py: 3, minHeight: '100%', }}>
                <Stack justifyContent={'center'} alignItems={'center'} sx={{ borderRadius: 100, color: '#FFF', px: 5, py: 1 }}>
                    <Typography variant="h4">{WdgjOrderNumber}</Typography>
                    <Typography variant="subtitle1" sx={{ opacity: 0.9, backgroundColor: color, color: '#FFF', borderRadius: 100, mt: 1.5, px: 2 }}>{WdgjOrderStatus}</Typography>
                </Stack>
                <Typography sx={{ mt: 0, color: '#ffffff99' }} variant="caption">{ImportWdgjOrderTime}</Typography>

                <Paper elevation={3} sx={{ width: '90%', borderRadius: 1, backgroundColor: '#fffffff5', px: 2, py: 2, mt: 3 }}>
                    <Typography sx={{ fontWeight: 700, color: '#666666' }} variant="subtitle2">收件人信息：</Typography>
                    <Stack sx={{ pl: 1 }}>
                        <Typography variant="subtitle1">{ReceiverName} {ReceiverMobile}</Typography>
                        <Typography variant="subtitle2">{Province} {City} {Area} {Street}</Typography>
                        <Typography variant="subtitle2">{Address}</Typography>
                    </Stack>
                    <Typography sx={{ fontWeight: 700, color: '#666666', mt: 2 }} variant="subtitle2">货品摘要：</Typography>
                    <Stack sx={{ pl: 1 }}>
                        <Typography variant="subtitle2"><div dangerouslySetInnerHTML={{ __html: ProsStr }}></div></Typography>
                    </Stack>
                    {GetWuLiu ?
                        <>
                            <Stack alignItems={'center'} sx={{ borderRadius: 1, backgroundColor: '#00b500', p: 1, mt: 2, color: '#FFF', position: "relative" }}>
                                <Typography variant="h6">{nkd}</Typography>
                                <Typography onClick={() => {
                                    copy(WuLiuNumber);
                                    setMsg(`复制${WuLiuNumber}成功`);
                                    setOpen(true);
                                }} variant="h6" sx={{ py: 0, fontSize: 28 }}>{WuLiuNumber}</Typography>
                                <ContentCopyIcon onClick={() => {
                                    copy(`${nkd} ${WuLiuNumber}`);
                                    setMsg(`复制 "${nkd} ${WuLiuNumber}" 成功`);
                                    setOpen(true);
                                }} sx={{ fontSize: 20, position: 'absolute', top: 6, right: 6 }} />
                            </Stack>
                            <Typography sx={{ textAlign: 'center', mt: 0.5, color: '#8c8c8c' }} variant="subtitle2">{GetWuLiuTime}</Typography>
                        </>
                        :
                        <Stack sx={{ borderRadius: 1, backgroundColor: '#ff8600', color: '#FFF', px: 3, py: 1, mt: 5, }}>
                            <Typography sx={{ textAlign: 'center' }} variant="h6">还没有物流单号哦！</Typography>
                        </Stack>
                    }
                </Paper>

                <MaView data={Ma} />
            </Stack>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={open}
                autoHideDuration={3000}
                onClose={() => setOpen(false)}
                message={msg}
            // action={action}
            />
        </Container >
    );
};


const MaView = ({ data }: any) => {
    let { success, data: maData } = data;
    if (success) {
        let { shortUrl } = maData;
        return (
            <Stack alignItems={'center'} sx={{ width: '90%', overflow: 'hidden', borderRadius: 1, px: 3, pt: 0, pb: 6, mt: 2, color: '#FFF' }}>
                <Typography variant='h6'>扫码订阅订单动态</Typography>
                <Typography variant='caption' sx={{ color: '#ffffffc9' }}>订单有动态时会推送消息到微信</Typography>
                <img style={{ marginTop: 4, width: '60%' }} src={shortUrl ?? ''} />
            </Stack>
        )
    }
    else {
        return null;
    }
}

export default ShareOrder;