import * as React from 'react';
import { Fragment, useCallback } from 'react';
import {
    useRedirect,
    List,
    Datagrid,
    TextField,
    Filter,
    TextInput,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    NumberInput,
    BulkDeleteWithConfirmButton,
    Button,
    SaveButton,
    Toolbar,
    TopToolbar,
    DeleteWithConfirmButton,
    FileInput, ImageField, ImageInput, FileField,
} from 'react-admin';

export const UpLoadFile = (props: any) => {

    const handleImageChange = (fileObj: any) => {
        //console.log('handleImageChange', fileObj)
        //fileObj[0].
        //setPreviewPath(fileObj[0].preview);
    };
    if (props.notImg)
        return (
            <FileInput {...props} options={{ onDrop: handleImageChange }} >
                <PreviewFile source={props.source} />
            </FileInput>
        );
    else
        return (
            <ImageInput {...props} options={{ onDrop: handleImageChange }} >
                {/* <TextField source="Logo" title="LOGO" /> */}
                {/* <PreviewImage source={props.source} /> */}
                <ImageField sx={{ "& .RaImageField-image": { width: 100, height: 100 } }} source="src" />
            </ImageInput>
        );
};

export const PreviewImage = (props: any) => {
    let { record, source, label, aliPicResize } = props;
    if (typeof (record) == "string") {
        record = {
            [source]: record
        }
    }
    if (aliPicResize) {
        let _aliPicResize = aliPicResize ?? "";
        let _imgPath = record[source];
        if (_imgPath.indexOf('.aliyuncs.com') < 0) _aliPicResize = '';
        return <div aria-label={label} className={props.className}><img src={_imgPath + _aliPicResize} /></div>;
    }
    return <ImageField {...props} label={label} record={record} source={source} />
}
export const PreviewFile = ({ record, source }: any) => {
    if (typeof (record) == "string") {
        record = {
            [source]: record
        }
    }
    else {
        record = {
            [source]: record.rawFile.name
        }
    }
    return <FileField record={record} source={source} title={record[source]} />
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const FirstImageField = (props: any) => {
    //console.log('props', props)
    let { label, record, source, subSource, aliPicResize } = props;
    let imgs = record[source];
    //console.log('imgs', imgs);
    if (imgs.length > 0) {

        let _aliPicResize = aliPicResize ?? "";
        let _imgPath = subSource != undefined ? imgs[0][subSource] : imgs[0];
        if (_imgPath.indexOf('.aliyuncs.com') < 0) _aliPicResize = '';

        return <div aria-label={label} className={props.className}><img src={_imgPath + _aliPicResize} /></div>;
    }
    return null;
};