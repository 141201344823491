import { Admin, Resource, ListGuesser, CustomRoutes, usePermissions } from "react-admin";
import jsonServerProvider from "ra-data-json-server";
import MyLogin from '../loginPage';
import authProvider from './authProvider';
import dataProvider from "./dataProvider";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import chinese from './chinese';
import { Route } from "react-router-dom";
import ChangePwd from "../customLayout/ChangePwd";
import SettingsIcon from '@mui/icons-material/Settings';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import CurrencyYenIcon from '@mui/icons-material/CurrencyYen';

// import { AdminCreate, AdminList } from './components/Admin';
import { DF_WdgjOrderList, DF_WdgjOrderCreate, DF_WdgjOrderEdit, DF_WdgjOrderShow } from './components/DF_WdgjOrder';
import { DF_WdgjOrderList as DF_Admin_WdgjOrderList } from './components/DF_Admin_WdgjOrder';
import { WdgjOrderList } from './components/WdgjOrder';
import { CategoryList } from './components/Category';
import { PriceList } from './components/Price';
import { ProductList } from './components/Product';
import { Pro_BrandList } from './components/Pro_Brand';
import { Pro_SupplierList } from './components/Pro_Supplier';
import { Pro_ChannelList } from './components/Pro_Channel';
import { Pro_ProductList, Pro_ProductCreate, Pro_ProductEdit, Pro_ProductShow } from './components/Pro_Product';
import { FaPiaoLogList, FaPiaoLogCreate, FaPiaoLogEdit, FaPiaoLogShow } from './components/FaPiaoLog';
import { DF_ProPriceList, DF_ProPriceCreate, DF_ProPriceEdit, DF_ProPriceShow } from './components/DF_ProPrice';

import MyLayout from "./customLayout/layout";
import Dashboard from "./components/dashboardPage";
import ShareOrder from "../customLayout/ShareOrder";
import { DF_Admin_JSWdgjOrderList } from "./components/DF_Admin_JSWdgjOrder";
import ShareJS from "../customLayout/ShareJS";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import CategoryIcon from '@mui/icons-material/Category';
import InventoryIcon from '@mui/icons-material/Inventory';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import BallotIcon from '@mui/icons-material/Ballot';
import RttIcon from '@mui/icons-material/Rtt';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'; 

// const dataProvider = jsonServerProvider("https://jsonplaceholder.typicode.com");

//设置语言为 中文
const i18nProvider = polyglotI18nProvider(() => chinese, 'zh', { allowMissing: true });

const App = () => {
    return (
        <Admin
            title="后台管理"
            loginPage={MyLogin}
            layout={MyLayout}
            dashboard={Dashboard}
            authProvider={authProvider}
            dataProvider={dataProvider}
            i18nProvider={i18nProvider}
        >
            {(permissions) => {
                console.log('permissions', permissions)
                let isAdmin = permissions === 'Admin';

                const adminList = isAdmin ? [
                    <Resource options={{ group: '结算汇总', label: '销售单', icon: <FormatListBulletedIcon /> }}
                        name="Admin/CMSAdminDF_WdgjOrder" list={DF_Admin_WdgjOrderList} />,
                    <Resource options={{ group: '结算汇总', label: '结算单', icon: <CreditScoreIcon /> }}
                        name="Admin/CMSAdminDF_JSWdgjOrder" list={DF_Admin_JSWdgjOrderList} />,
                    <Resource options={{ group: '结算汇总', label: '代发价', icon: <AttachMoneyIcon /> }}
                        name="Admin/CMSDF_ProPrice" list={DF_ProPriceList} create={DF_ProPriceCreate} edit={DF_ProPriceEdit} />,

                    <Resource options={{ group: '数据汇总', label: '购买记录', icon: <ManageSearchIcon /> }}
                        name="Admin/CMSWdgjOrder" list={WdgjOrderList} />,
                    <Resource options={{ group: '产品数据', label: '发票(Log)', icon: <RttIcon /> }}
                        name="Admin/CMSFaPiaoLog" list={FaPiaoLogList} show={FaPiaoLogShow} />,
                    <Resource options={{ group: '产品数据', label: '零售价(旧)(导入)', icon: <CurrencyYenIcon /> }}
                        name="Admin/CMSPrice" list={PriceList} />,
                    <Resource options={{ group: '产品数据', label: '分类(旧)', icon: <CategoryIcon /> }}
                        name="Admin/CMSCategory" list={CategoryList} />,
                    <Resource options={{ group: '产品数据', label: '产品数据(旧)', icon: <InventoryIcon /> }}
                        name="Admin/CMSProduct" list={ProductList} />,
                    <Resource options={{ group: '产品数据', label: '品牌', icon: <BrandingWatermarkIcon /> }}
                        name="Admin/CMSPro_Brand" list={Pro_BrandList} />,
                    <Resource options={{ group: '产品数据', label: '供应', icon: <BrandingWatermarkIcon /> }}
                        name="Admin/CMSPro_Supplier" list={Pro_SupplierList} />,
                    <Resource options={{ group: '产品数据', label: '渠道', icon: <BrandingWatermarkIcon /> }}
                        name="Admin/CMSPro_Channel" list={Pro_ChannelList} />,
                    <Resource options={{ group: '产品数据', label: '产品', icon: <BallotIcon /> }}
                        name="Admin/CMSPro_Product" list={Pro_ProductList} create={Pro_ProductCreate} edit={Pro_ProductEdit} show={Pro_ProductShow} />,
                ] : [];

                return [
                    <Resource options={{ group: '销售订单', label: '销售单', icon: <FormatListBulletedIcon /> }} name="Admin/CMSDF_WdgjOrder" list={DF_WdgjOrderList} create={DF_WdgjOrderCreate} />,

                    [...adminList],

                    <CustomRoutes>
                        <Route path="/ChangePwd" element={<ChangePwd />} />
                    </CustomRoutes>,
                    <CustomRoutes noLayout>
                        <Route path="/ShareOrder/:Sign" element={<ShareOrder />} />
                    </CustomRoutes>,
                    <CustomRoutes noLayout>
                        <Route path="/ShareJS/:Sign" element={<ShareJS />} />
                    </CustomRoutes>,
                ]
            }}
        </Admin>
    );
};

export default App;