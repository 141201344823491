import * as React from 'react';
import qs from 'qs';
import { Fragment } from 'react';
import { makeStyles } from 'tss-react/mui';
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, Form, useResourceContext, useNotify, FunctionField, useRefresh, useRecordContext } from 'react-admin';
import {
  useListContext,
  useRedirect,
  List,
  Datagrid,
  Filter,
  Edit,
  Create,

  Toolbar,
  TopToolbar,
  DeleteWithConfirmButton,
  BulkDeleteWithConfirmButton,
  Show,
  SimpleShowLayout,

  ReferenceField,
  TextField,
  ImageField,
  BooleanField,

  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  NumberInput,
  BooleanInput,
  NullableBooleanInput,

  Button,
  EditButton,
  ShowButton,
  SaveButton,
} from 'react-admin';
import { useForm, useFormContext } from 'react-hook-form';
import { Button as MButton, Box, useMediaQuery, Stack, Chip, CircularProgress } from '@mui/material';
import { PreviewImage, UpLoadFile } from './custom/UpLoadFile';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { useMutation } from 'react-query';
import { httpClient } from '../dataProvider';
import { apiUrl } from '../../global';
//import ResetOrderNum from './Product_ResetOrderNum';

{/* 
  import { ProductList, ProductCreate, ProductEdit, ProductShow } from './components/Product';

  <Resource name="CMSProduct" list={ProductList} create={ProductCreate} edit={ProductEdit} show={ProductShow} />
  <Resource name="CMSProduct" list={ListGuesser} create={EditGuesser} edit={EditGuesser} edit={ShowGuesser} /> 
*/}

const useStyles = makeStyles()((theme) => {
  return {
    imageField: {
      '& img.RaImageField-image': { width: 60 }
    },
  };
});


const useTxtData = {
  page: {
    tableName: 'Product',
  },
  table: {
    id: 'id',
    Name: 'Name',
    Pics: 'Pics',
    Code: 'Code',
    Age: 'Age',
    Desc: 'Desc',
    videoUrl: 'videoUrl',
    Sort: 'Sort',
    AtTime: 'AtTime',
    tableHtml: 'tableHtml',
    tableHtml2: 'tableHtml2',
    MPics: 'MPics',
    Code2: 'Code2',
  }
};

//分页列表页面
export const ProductList = () => {
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  // 筛选器模块
  // const Filters = () => {
  //   return (
  //     <Filter>
  //       <TextInput label="ID" source="id" alwaysOn />
  //     </Filter>
  //   );
  // };
  const MobileFilters = [
    <TextInput label="关键词" source="Key" alwaysOn resettable />
  ];
  const Filters = (props: any) => {
    const { displayedFilters, filterValues, setFilters, hideFilter, refetch } = useListContext();
    // if (isSmall) return null;
    if (props.context === "button") return null;
    const onSubmit = (values: any) => { if (Object.keys(values).length > 0) { setFilters(values, displayedFilters); } else { setFilters({}, displayedFilters); } refetch(); };
    const resetFilter = () => { setFilters({}, displayedFilters); };
    return (
      <div>
        <Form onSubmit={onSubmit} defaultValues={filterValues}>
          <Stack direction={'row'} alignItems={'baseline'} spacing={1.5}>
            <ReferenceInput source="CategoryId" reference="Admin/CMSCategory" perPage={999} >
              <SelectInput label="分类" optionText="Name" />
            </ReferenceInput>
            <TextInput label="关键词" source="Key" alwaysOn resettable />
            <MButton variant="contained" color="primary" type="submit" startIcon={<SearchIcon />}>搜索</MButton>
            <MButton variant="outlined" onClick={resetFilter} startIcon={<ClearAllIcon />}>重置</MButton>
          </Stack>
        </Form>
      </div>
    );
  };

  //批量操作模块
  const PostBulkActionButtons = () => {
    return (
      <Fragment>
        {/* <ResetOrderNum label="重置排序"  /> */}
        {/* <BulkDeleteWithConfirmButton confirmTitle="删除确认" confirmContent="确认要删除所选记录吗？" mutationMode='undoable' /> */}
      </Fragment>
    );
  };
  //行右侧功能按钮
  const RowAction = () => {
    const record = useRecordContext();
    let { id, Sort } = record;
    return (
      <div style={{ textAlign: 'right' }}>
        {/* <EditButton /><br />
        <ShowButton /><br />
        <DeleteWithConfirmButton confirmTitle="删除确认" confirmContent="确认要删除该记录吗？" /> */}
        {(Sort == 9 || Sort == 10) ? null :
          <>
            {isLoading ? <CircularProgress /> : <MButton onClick={() => { importPro(id); }}>导入</MButton>}
          </>
        }
      </div>
    );
  }
  const resource = useResourceContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const redirect = useRedirect();

  const { mutate: importPro, isLoading } = useMutation(
    (pid: any) => {
      return new Promise<any>((resolve, reject) => {
        var requestHeaders = new Headers();
        requestHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
        httpClient(`${apiUrl}/${resource}/ImportPro`, {
          method: 'POST',
          body: qs.stringify({ pid }),
          headers: requestHeaders,
        }).then(({ json }) => {
          if (json.Code == 1) {
            notify('导入成功', { type: 'success' });
            refresh();
            resolve(json);
          } else {
            notify(json.Message, { type: 'error' });
            reject(json.Message);
          }
        }).catch((e: any) => {
          let { status, body, name } = e;
          if (status === 401) {
            localStorage.removeItem('token');
            redirect(`/Login`);
          }
          console.log('e', status, body, name);
          reject(e);
        })
      });
    }
  );

  const { classes } = useStyles();
  return (
    <List
      title={useTxtData.page.tableName + '列表'}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<Filters />}
      exporter={false}
      sx={{ "& .MuiToolbar-root .MuiToolbar-root": { height: 0, minHeight: 0 } }}
    >
      <Datagrid
        // bulkActionButtons={<PostBulkActionButtons />}
        bulkActionButtons={false}
      >
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}

        <FunctionField label={useTxtData.table.Name} render={({ id, Sort }: any) => {
          return <>
            {Sort == 9 && <div><Chip size='small' color='secondary' label="导" /></div>}
            {Sort == 10 && <Stack direction={'row'}><Chip size='small' color='secondary' label="导" /><Chip sx={{ marginLeft: 0.4 }} size='small' color='success' label="主" /></Stack>}
            <div><TextField source="id" /></div>
          </>
        }} />

        <FunctionField label={useTxtData.table.Name} render={({ Age, Name, Code, Code2, videoUrl, Price_SRP }: any) => {
          return <>
            <div><TextField source="Name" /></div>
            <div><Chip size='small' label={Age} /></div>
            {Code != Code2 ? <Stack direction={'row'}><Chip size='small' label={Code2} /><Chip size='small' label={Code} /></Stack>
              : <div><Chip size='small' label={Code} /></div>
            }
            {Price_SRP && <div><Chip size='small' label={`¥${Price_SRP}`} /></div>}
            {
              videoUrl && <a target='_blank' href={videoUrl}>视频</a>
            }
          </>
        }} />

        <FunctionField label={useTxtData.table.Pics} sx={{ display: 'block', maxWidth: 350 }} render={({ MPics, Pics }: any) => {
          const picArr = ((MPics ?? Pics) ?? "").split(',');
          if (picArr.length == 1 && picArr[0] === '') return null;
          return picArr.map((src: string) => <a target='_blank' href={src}><img style={{ width: 100, height: 100 }} src={`https://img.scxiniu.com/unsafe/${80}x${80}/${src}`} /></a>)
        }} />
        <TextField label={useTxtData.table.Desc} source="Desc" sx={{ display: 'block', maxWidth: 300 }} sortable={false} />
        <FunctionField label={useTxtData.table.tableHtml} sx={{ display: 'block', maxWidth: 300, '& h1': { margin: 0, fontSize: 'medium' } }} render={({ tableHtml }: any) => {
          return <>
            <div dangerouslySetInnerHTML={{ __html: tableHtml }} />
          </>
        }} />
        <FunctionField label={useTxtData.table.tableHtml2} sx={{ display: 'block', maxWidth: 300, '& h1': { margin: 0, fontSize: 'medium' } }} render={({ tableHtml2 }: any) => {
          return <>
            <div dangerouslySetInnerHTML={{ __html: tableHtml2 }} />
          </>
        }} />

        <FunctionField label={useTxtData.table.AtTime} render={() => {
          return <>
            <TextField source="Sort" /><br />
            <TextField source="AtTime" />
          </>
        }} />

        <RowAction />
      </Datagrid>
    </List>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//SHOW页面
export const ProductShow = () => {
  const ShowActions = () => {
    const resource = useResourceContext();
    const redirect = useRedirect();
    return (
      <TopToolbar>
        <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text">
          <ArrowBackIcon />
        </Button>
        <EditButton />
      </TopToolbar>
    );
  };

  const { classes } = useStyles();
  return (
    <Show title={'查看' + useTxtData.page.tableName} actions={<ShowActions />}>
      <SimpleShowLayout>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        <TextField label={useTxtData.table.id} source="id" />
        <TextField label={useTxtData.table.Name} source="Name" />
        <TextField label={useTxtData.table.Pics} source="Pics" />
        <TextField label={useTxtData.table.Code} source="Code" />
        <TextField label={useTxtData.table.Age} source="Age" />
        <TextField label={useTxtData.table.Desc} source="Desc" />
        <TextField label={useTxtData.table.videoUrl} source="videoUrl" />
        <TextField label={useTxtData.table.Sort} source="Sort" />
        <TextField label={useTxtData.table.AtTime} source="AtTime" />
        <TextField label={useTxtData.table.tableHtml} source="tableHtml" />
        <TextField label={useTxtData.table.tableHtml2} source="tableHtml2" />
        <TextField label={useTxtData.table.MPics} source="MPics" />
        <TextField label={useTxtData.table.Code2} source="Code2" />
      </SimpleShowLayout>
    </Show>
  );
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//编辑页面
export const ProductEdit = () => {
  const resource = useResourceContext();
  const redirect = useRedirect();

  const PostEditActions = () => {
    return (
      <TopToolbar >
        <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text">
          <ArrowBackIcon />
        </Button>
      </TopToolbar>
    );
  };
  const PostEditToolbar = () => {
    return (
      <Toolbar >
        <SaveButton />
      </Toolbar>
    );
  };
  return (
    <Edit redirect={`/${resource}`} title={'编辑' + useTxtData.page.tableName} actions={<PostEditActions />}>
      <MyForm Edit={true} toolbar={<PostEditToolbar />} />
    </Edit>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//创建页面
export const ProductCreate = () => {
  const resource = useResourceContext();
  const redirect = useRedirect();
  const notify = useNotify();

  const PostCreateActions = () => {
    return (
      <TopToolbar>
        <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text" >
          <ArrowBackIcon />
        </Button>
      </TopToolbar>
    );
  };
  const PostCreateToolbar = () => {
    const { reset, resetField, handleSubmit, setValue, getValues, } = useFormContext();
    return (
      <Toolbar>
        <SaveButton alwaysEnable label="保存&列表" />
        <SaveButton alwaysEnable type='button' label="保存&继续" variant="text" mutationOptions={{
          onSuccess: () => {
            notify('创建成功', { type: 'success' });
            reset();
          }
        }} />
      </Toolbar>
    );
  };

  return (
    <Create redirect={`/${resource}`} title={'新增' + useTxtData.page.tableName} actions={<PostCreateActions />}>
      <MyForm Create={true} toolbar={<PostCreateToolbar />} />
    </Create>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//Create和Edit公用表单
const MyForm = (props: any) => {
  let { Edit, Create } = props;
  return (
    <SimpleForm {...props} >
      {/* <TextInput source="Name" /> */}
      {/* <NumberInput source="OrderNum" /> */}
      <TextInput label={useTxtData.table.id} source="id" validate={[required()]} />
      <TextInput label={useTxtData.table.Name} source="Name" validate={[required()]} />
      <TextInput label={useTxtData.table.Pics} source="Pics" validate={[required()]} />
      <TextInput label={useTxtData.table.Code} source="Code" validate={[required()]} />
      <TextInput label={useTxtData.table.Age} source="Age" validate={[required()]} />
      <TextInput label={useTxtData.table.Desc} source="Desc" validate={[required()]} />
      <TextInput label={useTxtData.table.videoUrl} source="videoUrl" validate={[required()]} />
      <TextInput label={useTxtData.table.Sort} source="Sort" validate={[required()]} />
      <TextInput label={useTxtData.table.AtTime} source="AtTime" validate={[required()]} />
      <TextInput label={useTxtData.table.tableHtml} source="tableHtml" validate={[required()]} />
      <TextInput label={useTxtData.table.tableHtml2} source="tableHtml2" validate={[required()]} />
      <TextInput label={useTxtData.table.MPics} source="MPics" validate={[required()]} />
      <TextInput label={useTxtData.table.Code2} source="Code2" validate={[required()]} />
    </SimpleForm>
  );
}