import { fetchUtils, HttpError } from "react-admin";
import { stringify } from "query-string";
import pLimit from "p-limit";
import { apiUrl } from "../global";

//const httpClient = fetchUtils.fetchJson;
export const httpClient = (url: string, options: any = {}) => {
  if (!options.headers) {
    //options.headers = new Headers({ Accept: 'application/json' });
    options.headers = new Headers();
  }
  // add your own headers here
  const token = localStorage.getItem("token");
  options.headers.set("Authorization", token);
  if (options.contentType)
    options.headers.set("Content-Type", options.contentType);

  return fetchUtils.fetchJson(url, options);
};

//下载文件
export const createHeadersFromOptions = (options: any) => {
  const requestHeaders =
    options.headers ||
    new Headers({
      Accept: "application/json",
    });
  if (
    !requestHeaders.has("Content-Type") &&
    !(options && (!options.method || options.method === "GET")) &&
    !(options && options.body && options.body instanceof FormData)
  ) {
    requestHeaders.set("Content-Type", "application/json");
  }
  if (options.user && options.user.authenticated && options.user.token) {
    requestHeaders.set("Authorization", options.user.token);
  }
  return requestHeaders;
};
export const httpClientReturnFile = (
  filename: string,
  url: string,
  options: any = {}
) => {
  if (!options.headers) {
    //options.headers = new Headers({ Accept: 'application/json' });
    options.headers = new Headers();
  }
  // add your own headers here
  const token = localStorage.getItem("token");
  options.headers.set("Authorization", token);
  if (options.contentType)
    options.headers.set("Content-Type", options.contentType);

  //return fetchUtils.fetchJson(url, options);
  const requestHeaders = createHeadersFromOptions(options);
  return fetch(url, { ...options, headers: requestHeaders })
    .then((response) => response.blob())
    .then((blob) => {
      var url = window.URL.createObjectURL(blob);
      // window.location.assign(url);
      // var a = document.createElement('a');
      // a.target = '_blank'; a.href = url;
      // a.download = `${filename}.xlsx`;
      // document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      // a.click(); a.remove();  //afterwards we remove the element again

      // var anchor = document.createElement('a');
      // anchor.style.display = 'none';
      // anchor.href = url;
      // anchor.target = "_blank";
      // anchor.download = `${filename}.xlsx`;
      // anchor.click();

      // window.location.href = url;

      // window.open(url)

      // Creating the hyperlink and auto click it to start the download
      // let link = document.createElement('a');
      // link.href = url;
      // link.download = 'dump.xlsx';
      // link.click();
      return Promise.resolve(url);
    })
    .catch(() => {
      return Promise.resolve(false);
    });
};

//压缩图片
const maxWidth = 1200; //1200px
const compressPicture = (file: any) =>
  new Promise((resolve, reject) => {
    try {
      let { name, type } = file.rawFile;
      if (
        type.toLowerCase() === "image/jpeg" ||
        type.toLowerCase() === "image/jpg" ||
        type.toLowerCase() === "image/png" ||
        type.toLowerCase() === "image/bmp"
      ) {
        let reader = new FileReader();
        reader.onload = () => {
          let imgBase64 = reader.result;
          let img = new Image();
          img.onload = function () {
            // 默认按比例压缩
            let w = img.width,
              h = img.height,
              scale = w / h;
            if (w <= maxWidth) resolve(file);
            else {
              console.log(
                "oldFile",
                `size(M):${file.rawFile.size / 1024 / 1024}`
              );
              // 默认图片质量为0.85
              let _q = 0.85,
                _w = maxWidth,
                _h = _w / scale;

              //生成canvas
              let canvas = document.createElement("canvas"),
                ctx = canvas.getContext("2d");
              canvas.width = _w;
              canvas.height = _h;
              ctx!.drawImage(img, 0, 0, _w, _h);

              // 回调函数返回base64的值
              var base64 = canvas.toDataURL(type, _q);

              // base64编码图片转为File类型
              var arr = base64.split(","),
                mime = (arr[0] ?? "").match(/:(.*?);/)![1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
              while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
              }
              var newFile = {
                Pics: file.Pics,
                rawFile: new File([u8arr], `new${name}`, { type: mime }),
              };

              console.log(
                "newFile",
                `size(M):${newFile.rawFile.size / 1024 / 1024}`
              );
              resolve(newFile);
            }
          };
          img.src = (imgBase64 ?? "").toString();
        };
        reader.readAsDataURL(file.rawFile);
      } else resolve(file);
    } catch (e) {
      console.log("compressPicture", e);
    }
  });

export const uploadFile = (file: any) =>
  new Promise(async (resolve, reject) => {
    //console.log('form value', file);
    if (file == null) {
      console.log("11");
      resolve(file);
    } else if (file.rawFile instanceof File) {
      console.log("22");
      //deal picture 压缩
      let file2: any = await compressPicture(file);
      //upload file
      var formData = new FormData();
      formData.append("file", file2.rawFile);
      httpClient(`${apiUrl}/File/UploadFile`, {
        method: "POST",
        body: formData,
      }).then(({ json }) => {
        if (json.Code === 1) {
          // resolve(json.Result.FilePath);
          resolve({ src: json.Result.FilePath, title: null });
        }
        reject(json.Message);
      });
    } else if (Object.prototype.toString.call(file) === "[object Object]") {
      console.log("33");
      // Nested object
      file = await getUrlFromFiles(file)
        .then((res) => res)
        .catch((e) => {
          reject(e);
        });
      resolve(file);
    } else {
      console.log("44");
      //already url
      resolve(file);
    }
  });

const getUrlFromFiles = (data: any) => {
  //console.log('data',data);return;
  return new Promise(async (resolve, reject) => {
    for (let key of Object.keys(data)) {
      let isArray = data[key] instanceof Array;
      let dataArr = isArray ? data[key] : [data[key]];
      if (dataArr.length > 0) {
        const limit = pLimit(3);

        let results = await Promise.all(
          dataArr.map((d: any) => limit(() => uploadFile(d)))
        )
          .then((res) => res)
          .catch((e) => {
            reject(e);
          });

        data[key] = isArray ? results : results![0];
      }
    }
    resolve(data);
  });
};

export default {
  getList: (resource: string, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      ...{ _page: page - 1, _pagesize: perPage }, //JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      ...{ _sort: field, _order: order }, //JSON.stringify([field, order]),
      ...params.filter,
    };

    //console.log('process.env.NODE_ENV:'+process.env.NODE_ENV);

    const url = `${apiUrl}/${resource}/GetList?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      if (json.Code == 0)
        return Promise.reject(new HttpError(json.Message, 406, ""));
      return {
        data: json.Result.data.map((item: any, index: number) => ({
          ...item,
          __index__: index + query._page * query._pagesize,
        })),
        total: json.Result.total,
      };
    });
  },

  getOne: (resource: string, params: any) => {
    return httpClient(`${apiUrl}/${resource}/GetOne/${params.id}`).then(
      ({ json }) => {
        if (json.Code == 0)
          return Promise.reject(new HttpError(json.Message, 406, ""));
        return { data: json.Result };
      }
    );
  },

  getMany: (resource: string, params: any) => {
    //console.log('params', params)
    const query = {
      ids: params.ids,
    };
    const url = `${apiUrl}/${resource}/GetMany?${stringify(query)}`;
    return httpClient(url).then(({ json }) => {
      if (json.Code == 0)
        return Promise.reject(new HttpError(json.Message, 406, ""));
      return { data: json.Result };
    });
  },

  getManyReference: (resource: string, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    /* const query = {
          sort: JSON.stringify([field, order]),
          range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
          filter: JSON.stringify({
            ...params.filter,
            [params.target]: params.id,
          }),
        }; */
    const query = {
      ...{ _page: page - 1, _pagesize: perPage }, //JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      ...{ _sort: field, _order: order }, //JSON.stringify([field, order]),
      ...{ [params.target]: params.id },
      ...params.filter,
    };

    const url = `${apiUrl}/${resource}/GetList?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      if (json.Code == 0)
        return Promise.reject(new HttpError(json.Message, 406, ""));
      return { data: json.Result.data, total: json.Result.total };
    });
  },

  create: (resource: string, params: any) => {
    return new Promise<any>(async (resolve, reject) => {
      //If has Files, get file url from upload file first
      params.data = await getUrlFromFiles(params.data)
        .then((res) => res)
        .catch((e) => {
          reject(new HttpError(e, 406, ""));
        });

      httpClient(`${apiUrl}/${resource}/Create`, {
        method: "POST",
        body: JSON.stringify(params.data),
      }).then(({ json }) => {
        if (json.Code == 0) reject(new HttpError(json.Message, 406, ""));
        resolve({ data: json.Result });
      });
    });
  },

  update: (resource: string, params: any) => {
    return new Promise<any>(async (resolve, reject) => {
      //If has Files, get file url from upload file first
      params.data = await getUrlFromFiles(params.data)
        .then((res) => res)
        .catch((e) => {
          reject(new HttpError(e, 406, ""));
        });

      httpClient(`${apiUrl}/${resource}/Update/${params.id}`, {
        method: "PUT",
        body: JSON.stringify(params.data),
      }).then(({ json }) => {
        if (json.Code == 0) reject(new HttpError(json.Message, 406, ""));
        resolve({ data: json.Result });
      });
    });
  },

  updateMany: (resource: string, params: any) => {
    const query = {
      ids: params.ids,
    };
    return httpClient(
      `${apiUrl}/${resource}/UpdateMany/${params.data.payload}?${stringify(
        query
      )}`,
      {
        method: "PUT",
        body: JSON.stringify(params.data.data),
      }
    ).then(({ json }) => {
      if (json.Code == 0)
        return Promise.reject(new HttpError(json.Message, 406, ""));
      return { data: json.Result };
    });
  },

  delete: (resource: string, params: any) =>
    httpClient(`${apiUrl}/${resource}/Delete/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => {
      if (json.Code == 0)
        return Promise.reject(new HttpError(json.Message, 406, ""));
      return { data: json.Result };
    }),

  deleteMany: (resource: string, params: any) => {
    // const query = {
    //   filter: JSON.stringify({id: params.ids}),
    // };
    //return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
    return httpClient(`${apiUrl}/${resource}/DeleteMany`, {
      method: "DELETE",
      body: JSON.stringify({ ids: params.ids }),
    }).then(({ json }) => {
      if (json.Code == 0)
        return Promise.reject(new HttpError(json.Message, 406, ""));
      return { data: json.Result };
    });
  },
};
