//export const webUrl = process.env.NODE_ENV === 'development' ? '' : '';

export const isDevelopment = process.env.NODE_ENV === "development";

export const apiUrl = isDevelopment
  ? "http://localhost:45215"
  : "https://qdapi.scxiniu.com";

export const dealWuLiuCom = (WuLiuCompany: any) => {
  let kd = [
    "顺丰",
    "申通",
    "圆通",
    "中通",
    "韵达",
    "邮政",
    "EMS",
    "丰网",
    "百世",
    "极兔",
    "德邦",
  ];
  let nkd = WuLiuCompany;
  for (var i in kd) {
    if ((WuLiuCompany ?? "").toUpperCase().indexOf(kd[i]) >= 0) {
      nkd = kd[i];
      break;
    }
  }
  return nkd;
};

export const GetWdgjOrderStatusCodeColor = (WdgjOrderStatusCode: any) => {
  var color = "#999";
  switch (WdgjOrderStatusCode ?? 0) {
    case 1010:
      color = "#FBD17F";
      break;
    case 1020:
      color = "#D2B48C";
      break;
    case 1030:
      color = "#D8D14C";
      break;
    case 1050:
      color = "#F9C18A";
      break;
    case 2010:
      color = "#D8B69A";
      break;
    case 2030:
    case 4114:
      color = "#FECBCC";
      break;
    case 3010:
      color = "#B5BC5B";
      break;
    case 4110:
    case 4050:
      color = "#71C5E2";
      break;
    case 4111:
      color = "#73B4E0";
      break;
    case 4112:
    case 4051:
      color = "#6EC4AE";
      break;
    case 4113:
    case 4052:
      color = "#C5A0EA";
      break;
    case 4121:
      color = "#E8A0D0";
      break;
    case 4122:
      color = "#AA2954";
      break;
    case 4123:
      color = "#77133C";
      break;
    case 4130:
      color = "#82CC7D";
      break;
    case 4040:
      color = "#F4BDBF";
      break;
    case 4041:
      color = "#A3B7A1";
      break;
    case 4042:
      color = "#B2552D";
      break;
    case 5010:
      color = "#FD5052";
      break;
    case 5020:
      color = "#F746B8";
      break;
    case 5030:
      color = "#D813CA";
      break;
    case 6000:
      color = "#14492B";
      break;
    case 9090:
      color = "#49CE75";
      break;
  }
  return color;
};

export const PublishQuDaos = ["闲鱼", "小红书"];
export const PTypes = [
  { id: "0", name: "采" },
  { id: "1", name: "销" },
];
